import React, { useEffect, useLayoutEffect, useState, ChangeEvent } from 'react';
import style from './ContractReportTable.module.scss';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { Box, IconButton, CircularProgress, Snackbar, Slide } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import MuiAlert from '@mui/material/Alert';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import { TableTemplate } from '@/components/Templates/TableTemplate';
import { useRootStore } from '@/stores';
import { TableRowStyled, TableCellStyled } from '@/components/Templates/TableComponentStyled';
import type { ILimitAsDebtor, ITableBodyDebtorsLimits } from '@/stores/1c/AccountsDebtorsLimitsStore';
import { formatLimitInput, formatSum, formatToCurrency } from '@/utils';
import { OFFSET, WIDTH } from '@/constants/sizes';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { TextFieldOutlinedStyled } from '@/components/atoms/TextFieldStyled';
import { TableTemplateWithoutStore } from '@/components/Templates/TableTemplateWithoutStore';
import { color } from '@/constants/colors';
import { IAccountWIthDetail } from '@/stores/ContragentsStore';
import { runInAction } from 'mobx';

const mapToLikeStore = (rawList: ILimitAsDebtor[]): Partial<ITableBodyDebtorsLimits>[] => {
  return rawList?.map((item) => ({
    id: item?.id,
    name: item?.parent_account?.name,
    inn: item?.parent_account?.inn,
    limit: item?.debtor_extension?.limit,
    loadedLimitPercent: item?.debtor_extension?.percent,
    loadedLimitAmount: item?.debtor_extension?.amountpp,
    freeLimit: item?.debtor_extension?.amountdt,
    fundingRatio: item?.debtor_extension?.cf,
    postponement: item?.debtor_extension?.postponement,
    regression: item?.debtor_extension?.regressionperiod,
    product: item?.debtor_extension?.product,
  })) as Partial<ITableBodyDebtorsLimits>[];
};

export type ITable = {
  tableRow: any[];
  tableCell: any[];
};
const tableHeader = [
  'Наименование дебитора',
  'Продукт',
  'Лимит на дебитора, руб.',
  'Загруженный лимит, руб.',
  'Загруженный лимит, %',
  'Свободный лимит',
  'Коэф. финансирования',
  'Отсрочка по договору поставки, дней',
  'Регресс дней',
];

const tableHeaderCredit = [
  'Наименование поставщика',
  'Продукт',
  'Лимит на дебитора, руб.',
  'Загруженный лимит, руб.',
  'Загруженный лимит, %',
  'Свободный лимит',
  'Коэф. финансирования',
  'Отсрочка по договору поставки, дней',
  'Регресс дней',
];

const slideTransition = (props: any) => {
  return <Slide {...props} direction="left" />;
};

const RenderBodyContactPersonsTable = ({ row, filled }: { row: Partial<ITableBodyDebtorsLimits>; filled: boolean }) => {
  const {
    id,
    name,
    inn,
    limit,
    loadedLimitPercent,
    loadedLimitAmount,
    freeLimit,
    fundingRatio,
    postponement,
    regression,
    product,
  } = row || {};
  return (
    <TableRowStyled key={id} sx={{ backgroundColor: filled ? color.light : color.transparent }}>
      <TableCellStyled>{`${name}  ${inn}`}</TableCellStyled>
      <TableCellStyled>{product}</TableCellStyled>
      <TableCellStyled>{limit !== undefined ? formatSum(limit) : '-'}</TableCellStyled>
      <TableCellStyled>{loadedLimitAmount !== undefined ? formatSum(loadedLimitAmount) : '-'}</TableCellStyled>
      <TableCellStyled>{loadedLimitPercent !== undefined ? formatSum(loadedLimitPercent) : '-'}</TableCellStyled>
      <TableCellStyled>{freeLimit !== undefined ? formatSum(freeLimit) : '-'}</TableCellStyled>
      <TableCellStyled>{fundingRatio}</TableCellStyled>
      <TableCellStyled>{postponement}</TableCellStyled>
      <TableCellStyled textAlign="right">{regression}</TableCellStyled>
    </TableRowStyled>
  );
};

export const ContractPersonLimits = observer(() => {
  const { contragentsStore, accountsDebtorsLimitsStore, isLoading } = useRootStore();
  const { id } = useParams<{ id: string }>();
  const [query, setQuery] = useState<string | undefined>(undefined);

  useLayoutEffect(() => {
    if (
      (accountsDebtorsLimitsStore.renderedRows.length === 0 || accountsDebtorsLimitsStore.contragentId !== id) &&
      id
    ) {
      accountsDebtorsLimitsStore.setContragenId(id);
    }
  }, []);

  const { limit, percent, amountpp, amountdt } = contragentsStore.selectedContragent?.account_extension || {};
  const { name } = contragentsStore.selectedContragent || {};

  const [customerLimit, setCustomerLimit] = useState<string>(limit ? formatLimitInput(limit.toString()) : '');
  const [loadedLimit, setLoadedLimit] = useState<string>(amountdt ? formatLimitInput(amountdt.toString()) : '');
  const [isDataEdited, setIsDataEdited] = useState<boolean>(false);

  useEffect(() => {
    setCustomerLimit(limit ? formatLimitInput(limit.toString()) : '');
    setLoadedLimit(amountdt ? formatLimitInput(amountdt.toString()) : '');
  }, [limit, amountpp]);

  const onChangeSearch = (e: any) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    let handler: NodeJS.Timeout;
    if (query?.length === 0) {
      handler = setTimeout(() => {
        accountsDebtorsLimitsStore.searchString = query;
      }, 1000);
    }

    if (isLoading || (query?.length as number) < 3) {
      return undefined;
    }

    handler = setTimeout(() => {
      runInAction(() => {
        accountsDebtorsLimitsStore.searchString = query;
      });
    }, 1000);

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [isLoading, accountsDebtorsLimitsStore, query]);

  const likeStore =
    accountsDebtorsLimitsStore.limitAsDebtor && mapToLikeStore(accountsDebtorsLimitsStore.limitAsDebtor);

  const handleCustomerLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/\s/g, '');
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    if (inputValue.length > 15) {
      inputValue = inputValue.substring(0, 15);
    }

    const dotIndex = inputValue.indexOf('.');
    if (dotIndex !== -1 && inputValue.lastIndexOf('.') !== dotIndex) {
      inputValue = inputValue.substring(0, inputValue.lastIndexOf('.'));
    }

    const parts = inputValue.split('.');
    if (parts.length > 1) {
      parts[1] = parts[1].substring(0, 2);
      inputValue = parts.join('.');
    }

    inputValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    setCustomerLimit(inputValue);
    setIsDataEdited(true);
  };

  const handleLoadedLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/\s/g, '');
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    if (inputValue.length > 15) {
      inputValue = inputValue.slice(0, 15);
    }
    const dotIndex = inputValue.indexOf('.');
    if (dotIndex !== -1) {
      const dotLastIndex = inputValue.lastIndexOf('.');
      if (dotIndex !== dotLastIndex) {
        inputValue = inputValue.slice(0, dotLastIndex) + inputValue.slice(dotLastIndex + 1);
      }
    }
    const parts = inputValue.split('.');

    if (parts.length > 1) {
      parts[1] = parts[1].substring(0, 2);
      inputValue = parts.join('.');
    }

    inputValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    setLoadedLimit(inputValue);
    setIsDataEdited(true);
  };

  const handleRollback = () => {
    setCustomerLimit(limit ? formatLimitInput(limit.toString()) : '');
    setLoadedLimit(amountdt ? formatLimitInput(amountdt.toString()) : '');
    setIsDataEdited(false);
  };

  const handleSaveForm = async () => {
    const customerLimitNumber = parseFloat(customerLimit.replace(/\s/g, ''));
    const loadedLimitNumber = parseFloat(loadedLimit.replace(/\s/g, ''));
    const cntrprt = contragentsStore.selectedContragent?.cntrprt;
    const inn = contragentsStore.selectedContragent?.inn;
    let account;
    if (id) {
      account = parseInt(id, 10);
    }
    const percent = (loadedLimitNumber / customerLimitNumber) * 100;
    const amountpp = customerLimitNumber - loadedLimitNumber;
    const putParameters: Partial<IAccountWIthDetail> = {
      inn,
      cntrprt,
      account_extension: {
        limit: customerLimitNumber,
        amountdt: loadedLimitNumber,
        account,
        percent,
        amountpp,
      },
    };

    await contragentsStore.putAccountById({ id: id ?? '', putParameters });
    setIsDataEdited(false);
  };
  return (
    <>
      {contragentsStore.selectedContragent?.account_extension?.source === 'SOURCE_1C' ? (
        contragentsStore.selectedContragent?.account_extension && (
          <Box
            mb={OFFSET.sm}
            sx={{
              height: '88px',
              marginTop: OFFSET.sm,
              background: color.light,
              border: `1px solid ${color.honeydew}`,
              borderRadius: '4px',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexDirection: 'row',
              display: 'flex',
              padding: '0 24px',
            }}
          >
            <LabelLikeHintWithText title="Имя" value={name} mb={OFFSET.xs} />
            <LabelLikeHintWithText title="Лимит на клиента, руб." value={formatToCurrency(limit)} mb={OFFSET.xs} />
            <LabelLikeHintWithText title="Загруженный лимит, руб." value={formatToCurrency(amountdt)} mb={OFFSET.xs} />
            <LabelLikeHintWithText title="Загруженный лимит, %." value={percent} mb={OFFSET.xs} />
            <LabelLikeHintWithText title="Свободный лимит" value={formatToCurrency(amountpp)} mb={OFFSET.xs} />
          </Box>
        )
      ) : (
        <Box
          mb={OFFSET.sm}
          sx={{
            height: '88px',
            marginTop: OFFSET.none,
            background: color.light,
            border: `1px solid ${color.honeydew}`,
            borderRadius: '4px',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            padding: '0 24px',
          }}
        >
          <LabelLikeHintWithText title="Имя" value={name} mb={OFFSET.xs} />
          <LabelLikeHintWithText
            title="Лимит на клиента, руб."
            handleInputChange={handleCustomerLimitChange}
            inputVisible={true}
            input={customerLimit}
            mb={OFFSET.xs}
          />
          <LabelLikeHintWithText
            title="Загруженный лимит, руб."
            handleInputChange={handleLoadedLimitChange}
            input={loadedLimit}
            inputVisible={true}
            mb={OFFSET.xs}
          />
          <LabelLikeHintWithText title="Загруженный лимит, %." value={percent} mb={OFFSET.xs} />
          <LabelLikeHintWithText title="Свободный лимит" value={formatToCurrency(amountpp)} mb={OFFSET.xs} />
        </Box>
      )}
      {!(!accountsDebtorsLimitsStore.renderedRows?.length && !isLoading) && (
        <TextFieldOutlinedStyled
          onChange={onChangeSearch}
          id="search"
          name="search"
          size="small"
          type="text"
          autoComplete="no"
          placeholder="Найти..."
          sx={{ width: WIDTH.s, marginBottom: '24px' }}
          value={query}
          endAdornment={
            isLoading && (
              <InputAdornment position="end">
                <CircularProgress size={20} />
              </InputAdornment>
            )
          }
          startAdornment={
            <InputAdornment position="start">
              <SearchOutlinedIcon />
            </InputAdornment>
          }
        />
      )}
      <TableTemplate<ITableBodyDebtorsLimits>
        isSkipEmptyImage={Boolean(likeStore?.length && likeStore.length > 0)}
        totalVisible={true}
        tableHeader={tableHeader}
        RenderComponent={(props) => <RenderBodyContactPersonsTable {...props} />}
        store={accountsDebtorsLimitsStore}
        isLoading={isLoading}
        isHidePagination
      />
      <TableTemplateWithoutStore<Partial<ITableBodyDebtorsLimits>>
        isSkipEmptyImage
        rowsPerPage={100}
        totalVisible={true}
        tableHeader={tableHeaderCredit}
        RenderComponent={(props) => <RenderBodyContactPersonsTable {...props} />}
        store={{
          renderedRows: likeStore ?? [],
        }}
        isLoading={isLoading}
      />
      <Box className={style.button}>
        {isDataEdited && (
          <>
            <IconButton onClick={handleSaveForm}>
              <CheckOutlinedIcon />
            </IconButton>
            <IconButton onClick={handleRollback}>
              <HighlightOffOutlinedIcon />
            </IconButton>
          </>
        )}
      </Box>
    </>
  );
});
