import { objectKeyUseLikeKeyValue, swopKeysAndValues } from '@/utils';

export const APP_STAGE = {
  Refinment: 'Уточнение данных',
  'Express Risk Assessment': 'Экспресс-оценка',
  'Committee Assessment': 'Кредитный комитет',
  Contracting: 'Заключение договора',
} as const;

export const APP_STAGE_TO_STAGE = objectKeyUseLikeKeyValue(APP_STAGE);

export const APP_STATUS = {
  New: 'Новая',
  'In Progress': 'В работе',
  'On Refining': 'На доработке',
  Completed: 'Выполнена',
  Rejected: 'Отклонена',
} as const;

type Signal = {
  code: string;
  name: string;
};

type AppStatus = {
  readonly [key: string]: Signal;
};

export const APP_STATUS_TO_STATUS = objectKeyUseLikeKeyValue(APP_STATUS);

export const APP_ACC_ROLE = {
  Client: 'Клиент',
  Debtor: 'Дебитор',
  Guarantee: 'Поручитель',
} as const;

export const APP_ACC_ROLE_TO_ROLE = objectKeyUseLikeKeyValue(APP_ACC_ROLE);

export const APP_SR_ROLE = {
  'Client Manager': 'Клиентский менеджер',
  'Credit Analyst': 'Кредитный аналитик',
  'Legal Advisor': 'Юридический консультант',
  'Security Advisor': 'Эксперт по безопасности',
};

export const APP_SR_ROLE_TO_ROLE = objectKeyUseLikeKeyValue(APP_SR_ROLE);

export const APP_FACT_TYPE = {
  'Regress factoring': 'Регрессный факторинг',
  'FNG shift': 'Рассрочка/отсрочка ФНГ',
  'ABL factoring': 'ABL факторинг',
  'Unregress factoring': 'Безрегрессный факторинг',
  'Tender factoring': 'Закупочный факторинг',
  'Agency factoring': 'Агентский факторинг',
  'Advance factoring': 'Авансовый факторинг',
  'Close factoring': 'Закрытый факторинг',
} as const;

export const APP_FACT_TYPE_TO_FACT_TYPE = objectKeyUseLikeKeyValue(APP_FACT_TYPE);

export const LEGAL_ENTITY_TYPE = {
  'Legal Entity': 'Юридическое лицо',
  Person: 'Индивидуальный предприниматель',
  Individual: 'Физическое лицо',
};
export const LEGAL_ENTITY_TYPE_TO_FACT_TYPE = objectKeyUseLikeKeyValue(LEGAL_ENTITY_TYPE);

export const ANALYTICS_STATUS = {
  Success: 'Успешно',
  Failed: 'Ошибка',
} as const;

export const RISK_LEVEL = {
  MEDIUM: 'Средний',
  HIGH: 'Высокий',
} as const;

export const PROBLEM_LEVEL = {
  Green: 'Зеленая зона',
  Yellow: 'Желтая зона',
  Red: 'Красная зона',
  Black: 'Черная зона',
} as const;

export const LIMIT_CHARGE = {
  Limit: 'Лимит',
  Charge: 'Загрузка',
} as const;

export const USER_COMPANY_GROUPS = {
  SmartFact: 'СмартФакт',
  BBR: 'ББР',
  ControlLeasing: 'Контрол Лизинг',
  Gilk: 'Гилк',
  FNG: 'ФНГ',
  CarbonCopy: 'Карбон Копи',
  BaltLeasing: 'Балтийский лизинг',
  Psbl: 'ПСБ Лизинг',
} as const;

export const SETTINGS_STATUS = {
  Draft: 'Черновик',
  Active: 'Активный',
  Archive: 'Архив',
} as const;

export const SETTINGS_STATUS_TO_STATUS = objectKeyUseLikeKeyValue(SETTINGS_STATUS);

export const STATUS = {
  Active: 'Активный',
  Inactive: 'Неактивный',
  New: 'Новый',
} as const;

export const STATUS_TO_STATUS = objectKeyUseLikeKeyValue(STATUS);

export const APP_STATUS_CUSTOM = {
  New: 'Новая',
  'In Progress': 'В работе',
  'On Refining': 'На доработке',
  Completed: 'Выполнена',
  Rejected: 'Отклонена',
  Approved: 'Одобрена',
  Revoked: 'Отозвана клиентом',
  Сlarification: 'На уточнении у клиента ',
} as const;

export const APP_STATUS_CUSTOM_TO_STATUS = objectKeyUseLikeKeyValue(APP_STATUS_CUSTOM);

export const APP_STATUS_CUSTOM_POSSIBLE = {
  [APP_STATUS_CUSTOM_TO_STATUS.New]: [
    APP_STATUS_CUSTOM_TO_STATUS['In Progress'],
    APP_STATUS_CUSTOM_TO_STATUS['On Refining'],
    APP_STATUS_CUSTOM_TO_STATUS.Completed,
    APP_STATUS_CUSTOM_TO_STATUS.Rejected,
  ],
  [APP_STATUS_CUSTOM_TO_STATUS['In Progress']]: [
    APP_STATUS_CUSTOM_TO_STATUS['On Refining'],
    APP_STATUS_CUSTOM_TO_STATUS.Completed,
    APP_STATUS_CUSTOM_TO_STATUS.Rejected,
  ],
  [APP_STATUS_CUSTOM_TO_STATUS['On Refining']]: [
    APP_STATUS_CUSTOM_TO_STATUS['In Progress'],
    APP_STATUS_CUSTOM_TO_STATUS.Completed,
    APP_STATUS_CUSTOM_TO_STATUS.Rejected,
  ],
  [APP_STATUS_CUSTOM_TO_STATUS.Revoked]: [APP_STATUS_CUSTOM_TO_STATUS['In Progress']],
  [APP_STATUS_CUSTOM_TO_STATUS['Сlarification']]: [
    APP_STATUS_CUSTOM_TO_STATUS.Revoked,
    APP_STATUS_CUSTOM_TO_STATUS['In Progress'],
    APP_STATUS_CUSTOM_TO_STATUS.Rejected,
  ],
  [APP_STATUS_CUSTOM_TO_STATUS.Approved]: [APP_STATUS_CUSTOM_TO_STATUS['In Progress']],
  [APP_STATUS_CUSTOM_TO_STATUS.Rejected]: [APP_STATUS_CUSTOM_TO_STATUS['In Progress']],
};

export const FILE_TYPE_REPORT = {
  accounting: 'Бухгалтерская отчётность',
  financial: 'Отчёт о финансовых результатах',
};

export const FILE_TYPE_REPORT_KEY = objectKeyUseLikeKeyValue(FILE_TYPE_REPORT);

export const LIST_NUMBER_REPORT = {
  1: 'Лист 1',
  2: 'Лист 2',
};

export const LIST_NUMBER_REPORT_KEY = objectKeyUseLikeKeyValue(LIST_NUMBER_REPORT);

export const FORMAT_DOCUMENT_REPORT = {
  word: 'Word',
  excel: 'Excel',
};

export const FORMAT_DOCUMENT_REPORT_KEY = objectKeyUseLikeKeyValue(FORMAT_DOCUMENT_REPORT);

export const ERROR_CODE_REPORT_FILE = {
  'PARS.ERR.INN': 'not valid inn',
  'PARS.ERR.DATE': 'not valid date',
  'PARS.ERR.TYPE': 'not valid type',
  'PARS.ERR.PAGE': 'not valid page',
};

export const ERROR_CODE_REPORT_FILE_ONLY_CODE = objectKeyUseLikeKeyValue(ERROR_CODE_REPORT_FILE);

export const MAP_VALUE_TYPE = {
  Id: 'Идентификатор',
  Integer: 'Целое число',
  Text: 'Текст',
  Float: 'Число',
  Boolean: 'Логический',
  DateTime: 'Дата и время',
};

export const VALUE_TYPE = {
  Id: 'Helper',
  Integer: 'Interval',
  Float: 'Interval',
  Text: 'Categorical',
  Boolean: 'Logical',
  DateTime: 'Helper',
};

export const MAP_DATA_TYPE = {
  Id: 'Идентификатор',
  Integer: 'Целое число',
  Text: 'Текст',
  Float: 'Число',
  Boolean: 'Логический',
};

export const APP_STATUS__MONIT: AppStatus = {
  NameSignal__1: {
    code: '01.101',
    name: 'Коэффициент общего долга',
  },
  NameSignal__2: {
    code: '01.102',
    name: 'Коэффициент краткосрочной нагрузки',
  },
  NameSignal__3: {
    code: '01.103',
    name: 'Способность обслуживания %% платежей',
  },
  NameSignal__4: {
    code: '01.104',
    name: 'Отрицательная динамика чистых активов',
  },
  NameSignal__5: {
    code: '01.105',
    name: 'Снижение выручки на протяжении 2-х последних отчетных периодов более чем на 20%',
  },
  NameSignal__6: {
    code: '01.106',
    name: 'Снижение выручки за отчетный период более чем на 50%',
  },
  NameSignal__7: {
    code: '01.107',
    name: 'Убыточная деятельность за отчетный период',
  },
  NameSignal__8: {
    code: '01.201',
    name: 'Изменение ЕИО',
  },
  NameSignal__9: {
    code: '01.202',
    name: 'Изменение юридического адреса',
  },
  NameSignal__10: {
    code: '01.203',
    name: 'Изменение состава участников контрагента',
  },
  NameSignal__11: {
    code: '01.204',
    name: 'Обременение доли участника контрагента',
  },
  NameSignal__12: {
    code: '01.205',
    name: 'Обременение выручки по контракту ',
  },
  NameSignal__13: {
    code: '01.206',
    name: 'Доля рассматриваемых Судом исковых требований контрагента (истец)',
  },
  NameSignal__14: {
    code: '01.207',
    name: 'Доля рассматриваемых Судом исковых требований к контрагенту  и ИП более 50% от среднемесячной выручки контрагента',
  },
  NameSignal__15: {
    code: '01.208',
    name: 'Подача иска к контрагенту о несостоятельности/банкротстве или сообщение кредитора о намерении обратиться в суд с заявлением о банкротстве',
  },
  NameSignal__16: {
    code: '01.209',
    name: 'Подача и/или принятие Судом решения о введении любой из процедур несостоятельности в отншении аффилированной с контрагентом компании (За последние 36 мес.).',
  },
  NameSignal__17: {
    code: '01.210',
    name: 'Начиличие судебных споров между Клиентом и Дебитором',
  },
  NameSignal__18: {
    code: '01.211',
    name: 'Реорганизация контрагента с прекращением деятельности юр. Лица',
  },
  NameSignal__19: {
    code: '01.212',
    name: 'Контрагент объявил о начале процедуры ликвидации',
  },
  NameSignal__20: {
    code: '01.213',
    name: 'Выявлена существенная негативная информация в отношении контрагента и/или аффилированной с контрагентом компанией',
  },
  NameSignal__21: {
    code: '01.214',
    name: 'Наличие блокировок по р/с за последние 12 месяцев',
  },
  NameSignal__22: {
    code: '01.301',
    name: 'Просрочка исполнения обязательств контрагентом свыше 5 к.д.',
  },
  NameSignal__23: {
    code: '01.302',
    name: 'Просрочка исполнения обязательств контрагентом свыше 30 к.д.',
  },
  NameSignal__24: {
    code: '01.303',
    name: 'Просрочка исполнения обязательств контрагентом свыше 60 к.д.',
  },
  NameSignal__25: {
    code: '01.304',
    name: 'Просрочка исполнения обязательств контрагентом свыше 90 к.д.',
  },
  NameSignal__26: {
    code: '01.305',
    name: 'Сообщение контрагента о невозможности исполнить обязательства в установленный срок',
  },
  NameSignal__27: {
    code: '01.306',
    name: 'Отказ контрагента от сотрудничества и/или исполнения обязательтсв перед СмартФакт',
  },
  NameSignal__28: {
    code: '01.307',
    name: 'Вхождение контрагента и/или аффилированных лиц в "черный список Группы Компаний"',
  },
  NameSignal__29: {
    code: '01.308',
    name: 'Признание задолженности контагента и/или аффилированной компании проблемной задолженностью',
  },
  NameSignal__30: {
    code: '01.309',
    name: 'Дефолт контрагента и/или признание задолженности контагента проблемной задолженностью перед Компаниями Группы Компаний',
  },
  NameSignal__31: {
    code: '01.310',
    name: 'Прямые платежи клиента за дебитора',
  },
  NameSignal__32: {
    code: '01.008',
    name: 'Иное ухудшение финансового положения',
  },
  NameSignal__33: {
    code: '01.215',
    name: 'Рост арбитражной активности контрагента (ответчик)',
  },
} as const;

export const APPLICATION_TYPE = {
  'Установление лимита': 'Установление лимита',
  // 'Акутализация лимита': 'Акутализация лимита',
  'Изменение параметров лимита': 'Изменение параметров лимита',
  // 'Предварительный скоринг': 'Предварительный скоринг',
  // 'Оценка поручителя': 'Оценка поручителя',
  // 'Ежеквартальный фин. мониторинг': 'Ежеквартальный фин. мониторинг',
};

export const APPLICATION_TYPE_KEY = objectKeyUseLikeKeyValue(APPLICATION_TYPE);

export const DOCUMENT_TYPE = {
  '1': 'Юридические документы',
  '2': 'Финансовые документы',
  '3': 'Договоры и уведомления',
  '4': 'Решения и заключения',
  '5': 'Документы по заявке',
  '6': 'Прочие документы',
} as const;

export const DOCUMENT_TYPE_KEY = objectKeyUseLikeKeyValue(DOCUMENT_TYPE);

export const DOCUMENT_TYPE_SWOP = swopKeysAndValues(DOCUMENT_TYPE);
