import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Box, Button, IconButton, Tooltip } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import { TableTemplateWithoutStore } from '@/components/Templates/TableTemplateWithoutStore';
import { ButtonStyled } from '@/components/atoms/Button';
import { Dropdown } from '@/components/atoms/Dropdown';
import { File, Char } from '@/assets/index';
import { WIDTH } from '@/constants/sizes';
import { FileUploader } from '@/components/atoms/FileUploader';
import { TableRowStyled, TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { ROUTES } from '@/constants';
import { useRootStore } from '@/stores';
import type { IApplicationAnalysis, IApplicationCondition } from '@/stores/ApplicationsPageDetailsStore';
import { ANALYTICS_STATUS } from '@/constants/backendEnum';
import { Badge } from '@/components/atoms/Bage/Badge';
import { ModalDelete } from '@/components/common/ModalDelete';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { EditButtons } from '@/components/Templates/EditButtons';
import { color } from '@/constants/colors';

export type ITableBodyAnalysis = {
  id: string | number;
  file: string;
  status: string;
  period: string | null;
  debitor: string;
  analysis?: string | number | null;
  error_msg?: string | null;
  filePath?: string;
};

const tableHeader = ['Файл', 'Статус', 'Период', 'Дебитор', 'Аналитика', ''];

const RenderBodyAnalysisTable = ({
  row,
  filled,
  deleteFile,
}: {
  row: ITableBodyAnalysis;
  filled: boolean;
  deleteFile: (k62: K62Row) => void;
}) => {
  const { applicationsPageDetailsStore } = useRootStore();
  const navigation = useNavigate();

  const onClick = () => {
    navigation(`${row.id}/${row.debitor ? 62 : 51}${ROUTES.monitoring}`);
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    deleteFile(row as K62Row);
  };

  const handleLoadFile = (e: any) => {
    e.stopPropagation();
    if (row.filePath) {
      const link = document.createElement('a');

      link.download = row.filePath;
      link.href = row.filePath;
      link.click();
    }
  };

  return (
    <TableRowStyled key={row.id} sx={{ backgroundColor: filled ? color.light : color.transparent }} onClick={onClick}>
      <TableCellStyled>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <File />
          <Box sx={{ width: 24, height: 10 }} />
          {row.file}
          <IconButton onClick={handleLoadFile}>
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Box>
      </TableCellStyled>
      <TableCellStyled>
        {row.error_msg ? (
          <Tooltip title={row.error_msg} followCursor>
            <Button
              disableElevation
              disableRipple
              disableFocusRipple
              disableTouchRipple
              sx={{
                textDecorationLine: 'none',
              }}
            >
              <Badge type={row.status?.toLowerCase()}>{row.analysis ?? '-'}</Badge>
            </Button>
          </Tooltip>
        ) : (
          <Badge type={row.status?.toLowerCase()}>{row.analysis ?? '-'}</Badge>
        )}
      </TableCellStyled>
      <TableCellStyled>{row.period}</TableCellStyled>
      <TableCellStyled>{row.debitor}</TableCellStyled>
      <TableCellStyled>
        <Char />
      </TableCellStyled>
      <EditButtons disabled={!applicationsPageDetailsStore.isPossibleEdit} handleDelete={handleDelete} />
    </TableRowStyled>
  );
};

const templateDropdownList = (data: IApplicationCondition[]) => ({
  id: 'company',
  placeholder: 'Дебитор',
  dropdownItems: data.map((item) => ({
    label: item.account_name,
    value: item.id,
  })),
});

const AddGuarantor = () => {
  const [isOpenAddGuarantor, setIsOpenAddGuarantor] = useState<boolean>(false);
  const [contragent, setContragent] = useState<string | null>();
  const [fileType, setFileType] = useState<number>(62);
  const [file, setFile] = useState<any>();
  const { applicationsPageDetailsStore } = useRootStore();
  const { id } = useParams<{ id: string }>();

  const toggleIsOpenAddGuarantor = () => {
    setIsOpenAddGuarantor(!isOpenAddGuarantor);
  };

  const handleChangeDadata = (name: string, value: string) => {
    setContragent(value);
  };

  const handleChangeFileType = (name: string, value: string) => {
    setFileType(+value);
  };

  useEffect(() => {
    if (id) {
      applicationsPageDetailsStore.getConditionsByApplicationId({ applicationId: id });
    }
  }, [id, applicationsPageDetailsStore]);

  const handleChangeFile = (value: File) => {
    setFile(value);
  };

  const handleConfirm = () => {
    if (id) {
      applicationsPageDetailsStore.createAnalysisByApplicationId({
        applicationId: id,
        debtor: contragent,
        file,
        fileType,
      });
      toggleIsOpenAddGuarantor();
    }
  };

  useEffect(() => {
    if (isOpenAddGuarantor) {
      setIsOpenAddGuarantor(!isOpenAddGuarantor);
    }
  }, [applicationsPageDetailsStore?.isPossibleEdit]);

  const templateDropdown = useMemo(
    () =>
      applicationsPageDetailsStore.conditionApplicationById
        ? templateDropdownList(applicationsPageDetailsStore.conditionApplicationById)
        : ({} as any),
    [applicationsPageDetailsStore.conditionApplicationById],
  );

  const templateDropdownFileType = useMemo(() => {
    return [
      {
        label: 'K62',
        value: 62,
      },
      {
        label: 'K51',
        value: 51,
      },
    ];
  }, []);

  return (
    <Box
      sx={{
        margin: '0 24px 0 60px',
        height: '90px',
        display: 'flex',
        alignItems: 'center',
        flex: '1 0 auto',
      }}
    >
      {isOpenAddGuarantor ? (
        <>
          <Dropdown
            mb={0}
            mr="24px"
            dropdownWidth={WIDTH.auto}
            id="fileType"
            dropdownItems={templateDropdownFileType}
            title="Тип файла"
            value={fileType}
            onChange={handleChangeFileType}
          />
          <Dropdown
            mb={0}
            mr="24px"
            dropdownWidth={WIDTH.auto}
            id={templateDropdown?.id}
            dropdownItems={templateDropdown?.dropdownItems ?? []}
            title={templateDropdown?.placeholder}
            onChange={handleChangeDadata}
            disabled={fileType === 51}
          />
          <FileUploader sx={{ marginRight: '24px', flex: '1 0 auto' }} handleFile={handleChangeFile} />
          <ButtonStyled
            text="Добавить"
            customType="default"
            onClick={handleConfirm}
            sx={{ marginRight: '24px' }}
            disabled={!file || (!contragent && fileType === 62)}
          />
          <ButtonStyled text="Отменить" customType="error" variant="outlined" onClick={toggleIsOpenAddGuarantor} />
        </>
      ) : (
        <ButtonStyled
          text="ДОБАВИТЬ"
          variant="outlined"
          customType="success"
          height="36px"
          disabled={!applicationsPageDetailsStore?.isPossibleEdit}
          onClick={toggleIsOpenAddGuarantor}
        />
      )}
    </Box>
  );
};

const mapToLikeStore = (data: IApplicationAnalysis[]) =>
  data.map((item) => ({
    id: item.id,
    file: item.file_name,
    status: item.status,
    period: item.file_period,
    debitor: item.debtor_name,
    error_msg: item.error_msg,
    analysis: ANALYTICS_STATUS[item.status as keyof typeof ANALYTICS_STATUS],
    filePath: item?.file_path,
  }));

type K62Row = ReturnType<typeof mapToLikeStore>[0];

export const Analysis = observer(() => {
  const { open, handleClose, handleOpen, deletedValue: k62 } = useModalDelete<K62Row>();
  const { applicationsPageDetailsStore, isLoading } = useRootStore();
  const { id } = useParams<{ id: string }>();

  const handleDelete = () => {
    if (id && k62?.id) {
      applicationsPageDetailsStore.k62Delete({ applicationId: id, k62Id: k62.id, fileType: k62?.debitor ? 62 : 51 });
      handleClose();
    }
  };

  useEffect(() => {
    if (id) {
      applicationsPageDetailsStore.getAnalysisByApplicationId({ applicationId: id });
    }
  }, [id, applicationsPageDetailsStore]);

  const likeStore = useMemo(
    () =>
      applicationsPageDetailsStore?.analysisApplicationById
        ? mapToLikeStore(applicationsPageDetailsStore.analysisApplicationById)
        : [],
    [applicationsPageDetailsStore.analysisApplicationById],
  );

  return (
    <>
      <TableTemplateWithoutStore<ITableBodyAnalysis>
        tableHeader={tableHeader}
        RenderComponent={(props) => <RenderBodyAnalysisTable {...props} deleteFile={handleOpen} />}
        store={{
          renderedRows: (likeStore as ITableBodyAnalysis[]) ?? [],
        }}
        lastRowItem={<AddGuarantor />}
        isLoading={isLoading}
      />
      <ModalDelete
        uniqId="k62_delete_modal"
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить файл?"
        descriptions={[k62?.file ?? '', k62?.period ?? '', k62?.debitor ?? '']}
      />
    </>
  );
});
