import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { TableTemplateWithoutStore } from '@/components/Templates/TableTemplateWithoutStore';
import { TableRowStyled, TableCellStyled } from '@/components/Templates/TableComponentStyled';
import { useRootStore } from '@/stores';
import type { IApplicationCondition } from '@/stores/ApplicationsPageDetailsStore';
import { APP_FACT_TYPE, APP_FACT_TYPE_TO_FACT_TYPE, PROBLEM_LEVEL } from '@/constants/backendEnum';
import { formatToCurrency } from '@/utils';
import { withEditable } from '@/hoc/withEditable';
import { EditButtons } from '@/components/Templates/EditButtons';
import { useValidation } from '@/hooks/useValidation';
import { EditableField, TableCellEditable } from '@/components/Templates/TableCellEditable';
import { apiPath } from '@/constants/api';
import { ModalDelete } from '@/components/common/ModalDelete';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { color } from '@/constants/colors';
import { ROUTES } from '@/constants';
import { RETURN_TABS } from '@/pages/ApplicationPageDetails';
import { SelectStyled } from '@/components/atoms/Select/SelectStyled';

export type ITableBodyConditions = {
  id: string | number;
  debitor: string;
  inn: string | number;
  monitoring?: string | number | null;
  limit: number | string;
  postponement: number | string;
  workingTime: number | string;
  type: string;
  account: number;
  problem_level: string;
  preferentialPeriod: string | number;
  role: string;
  cntrprt: number;
};

const tableHeader = [
  'Дебитор',
  'ИНН',
  'Лимит, ₽',
  'Отсрочка, к/д',
  'Льготный период',
  'Срок работы с\nдебитором, мес',
  'Тип факторинга',
  'Зона проблемности',
  '',
];

const tableHeaderWidth = ['11%', '11%', '11%', '11%', '11%', '16%', '11%', '11%', '7%'];

export const generateFactoringDropdownItems = (
  appFactType: typeof APP_FACT_TYPE,
  appFactTypeToFactType: typeof APP_FACT_TYPE_TO_FACT_TYPE,
) => {
  return Object.keys(appFactType).map((key) => ({
    label: appFactType[key as keyof typeof appFactType],
    value: appFactTypeToFactType[key as keyof typeof appFactTypeToFactType],
  }));
};

const RenderBodyApplicationsTableRaw = ({
  row,
  filled,
  isEdit,
  handleOpenEdit,
  handleCloseEdit,
  handleOpenDeleteModal,
}: {
  row: ITableBodyConditions;
  filled: boolean;
  isEdit: boolean;
  handleOpenEdit: () => void;
  handleCloseEdit: () => void;
  handleOpenDeleteModal: (deletedValue: ITableBodyConditions) => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { applicationsPageDetailsStore, contragentsStore } = useRootStore();
  const usedUrl = useMemo(
    () => apiPath.conditionAcc.replace('{application_pk}', `${id}`).replace('{id}', `${row.id}`),
    [id, row.id],
  );

  const onRowCLick = () => {
    navigate(`${ROUTES.contragents}/${row.account}`, { state: { route: RETURN_TABS[0], applicationId: id } });
    contragentsStore.getAccountById(`${row.account}`);
  };

  const { value, isError, formHasErrorRaw, setFormHasError, onChangeDropdown, handleChangeInput } = useValidation();

  const handleEditDone = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const cleanNumber = (numStr: string) => {
      return numStr.replace(/\s/g, '').replace(',', '.');
    };

    if (row.id && id && value) {
      const limit = cleanNumber(value?.limit);

      applicationsPageDetailsStore.updateConditions({
        url: usedUrl,
        paramsToPatch: {
          factoring_type: value?.factType,
          collab_months: value?.workingTime,
          deferment_days: value?.postponement,
          limit: limit,
          preferential_period: value?.preferentialPeriod,
          role: row.role,
          cntrprt: row.cntrprt,
        },
        applicationId: id,
      });
    }
  };

  const handleOpenDelete = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation();
    handleOpenDeleteModal(row);
  };

  return (
    <TableRowStyled
      key={row.id}
      sx={{ backgroundColor: filled ? color.light : color.transparent }}
      onClick={isEdit ? () => {} : onRowCLick}
    >
      <TableCellStyled verticalAlign="middle">{row.debitor}</TableCellStyled>
      <TableCellStyled verticalAlign="middle">{row.inn}</TableCellStyled>
      <TableCellEditable
        initialVal={row.limit}
        fieldName="limit"
        handleChange={handleChangeInput}
        type={EditableField.inputRequired}
        isEdit={isEdit}
        setFormHasError={setFormHasError}
        formHasErrorRaw={formHasErrorRaw}
        required
      />
      <TableCellEditable
        initialVal={row.postponement}
        fieldName="postponement"
        handleChange={handleChangeInput}
        type={EditableField.inputRequired}
        isEdit={isEdit}
        setFormHasError={setFormHasError}
        formHasErrorRaw={formHasErrorRaw}
        required
      />
      <TableCellEditable
        initialVal={row.preferentialPeriod}
        fieldName="preferentialPeriod"
        handleChange={handleChangeInput}
        type={EditableField.inputRequired}
        isEdit={isEdit}
        setFormHasError={setFormHasError}
        formHasErrorRaw={formHasErrorRaw}
        required
      />
      <TableCellEditable
        initialVal={row.workingTime}
        fieldName="workingTime"
        handleChange={handleChangeInput}
        type={EditableField.inputRequired}
        isEdit={isEdit}
        setFormHasError={setFormHasError}
        formHasErrorRaw={formHasErrorRaw}
        required
      />
      <TableCellEditable
        required
        initialVal={row.type in APP_FACT_TYPE ? `${APP_FACT_TYPE[row.type as keyof typeof APP_FACT_TYPE]}` : '-'}
        fieldName="factType"
        handleChange={onChangeDropdown}
        type={EditableField.dropdown}
        isEdit={isEdit}
        setFormHasError={setFormHasError}
        formHasErrorRaw={formHasErrorRaw}
        dropdownInfo={{
          value: row.type as keyof typeof APP_FACT_TYPE,
          dropdownItems: generateFactoringDropdownItems(APP_FACT_TYPE, APP_FACT_TYPE_TO_FACT_TYPE),
        }}
      />
      <TableCellStyled verticalAlign="middle">
        <SelectStyled
          defValue={PROBLEM_LEVEL[row.problem_level as keyof typeof PROBLEM_LEVEL]}
          items={PROBLEM_LEVEL}
          title={''}
          disabled
        />
      </TableCellStyled>

      <EditButtons
        isEdit={isEdit}
        isError={isError}
        disabled={!applicationsPageDetailsStore.isPossibleEdit}
        handleOpen={handleOpenEdit}
        handleClose={handleCloseEdit}
        handleSave={handleEditDone}
        handleDelete={handleOpenDelete}
      />
    </TableRowStyled>
  );
};

const RenderBodyApplicationsTable = withEditable(RenderBodyApplicationsTableRaw);

const mapToLikeStore = (data: IApplicationCondition[]) =>
  data.map((item) => ({
    id: item.id,
    debitor: item.account_name ?? '-',
    inn: item?.account_inn ?? '-',
    monitoring: item?.account_risk_level,
    limit: formatToCurrency(item.limit),
    postponement: item.deferment_days,
    workingTime: item.collab_months,
    type: item.factoring_type,
    account: item.account,
    preferentialPeriod: item.preferential_period ?? '-',
    problem_level: item.account_problem_level,
    role: item.role,
    cntrprt: item.cntrprt,
  }));

export const ApplicationAddTable = observer(() => {
  const { applicationsPageDetailsStore, isLoading } = useRootStore();
  const { id } = useParams<{ id: string }>();
  const { open, handleClose, handleOpen, deletedValue: debtor } = useModalDelete<ITableBodyConditions>();

  useEffect(() => {
    if (id) {
      applicationsPageDetailsStore.getConditionsByApplicationId({ applicationId: id });
    }
  }, [id]);

  const likeStore =
    applicationsPageDetailsStore.conditionApplicationById &&
    mapToLikeStore(applicationsPageDetailsStore.conditionApplicationById);

  const handleDelete = () => {
    if (id && debtor?.id) {
      applicationsPageDetailsStore.deleteCondition({ applicationId: id, debtorId: debtor?.id });
      handleClose();
    }
  };

  return (
    <>
      <TableTemplateWithoutStore<ITableBodyConditions>
        tableHeader={tableHeader}
        tableHeaderWidth={tableHeaderWidth}
        RenderComponent={(props) => <RenderBodyApplicationsTable {...props} handleOpenDeleteModal={handleOpen} />}
        store={{
          renderedRows: (likeStore as ITableBodyConditions[]) ?? ([] as ITableBodyConditions[]),
        }}
        isLoading={isLoading}
      />
      <ModalDelete
        uniqId="Debtor_delete_modal"
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить дебиотора?"
        descriptions={[debtor?.debitor ?? '', debtor?.inn ?? '']}
      />
    </>
  );
});
