import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Box, CircularProgress, Grid, InputAdornment, Paper, SelectChangeEvent, Typography } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { OFFSET, WIDTH } from '@/constants/sizes';
import { TextFieldOutlinedStyled } from '@/components/atoms/TextFieldStyled';
import { useRootStore } from '@/stores';
import { FilterItem, FilterType, IButtonGroupItem } from '@/constants/types';
import { FilterStatus, useFilters } from '@/components/atoms/FilterStatus';
import { MonitoringDatePicker } from '@/components/Monitoring/MonitoringDatePicker/MonitoringDatePicker';
import { ButtonStyled } from '@/components/atoms/Button';
import { ContractDocumentsModalCreate } from './ContractDocumentsModal';
import { ButtonGroupStyled } from '@/components/common/ButtonGroupStyled';
import { DOCUMENT_TYPE_SWOP } from '@/constants/backendEnum';
import { ContractDocumentsTable } from './ContractDocumentsTable';
import { runInAction } from 'mobx';
import { useParams } from 'react-router-dom';

const filterLabels = {
  DOCUMENT_TYPE: 'Вид документа',
};

const filterItems: FilterItem[] = [
  {
    label: filterLabels.DOCUMENT_TYPE,
    items: {},
    width: '128px',
  },
];

const initialFilterStatus: FilterType = {
  [filterLabels.DOCUMENT_TYPE]: [],
};

const buttonItems: IButtonGroupItem<string>[] = [
  {
    name: 'Юридические документы',
    type: '1',
  },
  {
    name: 'Финансовые документы',
    type: '2',
  },
  {
    name: 'Договоры и уведомления',
    type: '3',
  },
  {
    name: 'Решения и заключения',
    type: '4',
  },
  {
    name: 'Документы по заявке',
    type: '5',
  },
  {
    name: 'Прочие документы',
    type: '6',
  },
];

export const ContragentDocuments = observer(({ sourceContext }: { sourceContext: 'application' | 'contragent' }) => {
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<FilterType>(initialFilterStatus);
  const [selectedFilters, setSelectedFilters] = useState<FilterType>(initialFilterStatus);
  const [dateStartSignal, setDateStartSignal] = useState<string>('');
  const [dateEndSignal, setDateEndSignal] = useState<string>('');
  const { isLoading, documentsStore, applicationsPageDetailsStore } = useRootStore();
  const { id } = useParams<{ id: string }>();

  const counterpartyID = applicationsPageDetailsStore.contragentApplicationById?.id;

  const { open: openFilter, handleOpen, handleClose } = useFilters();

  const toggleOpen = () => {
    if (!isLoading) {
      setOpenAdd(!openAdd);
    }
  };

  const onChangeSearch = (e: any) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    if ((documentsStore.renderedRows?.length === 0 || documentsStore.contragentId !== id) && id) {
      documentsStore.setModelId(id);
      documentsStore.setSourceContext(sourceContext);

      if (sourceContext === 'contragent') {
        documentsStore.setContragentId(id);
      } else if (counterpartyID) {
        documentsStore.setContragentId(counterpartyID.toString());
      }
    }
  }, [id, documentsStore, sourceContext, counterpartyID]);

  useEffect(() => {
    let handler: NodeJS.Timeout;

    if (query?.length === 0) {
      handler = setTimeout(() => {
        runInAction(() => {
          documentsStore.searchString = query;
        });
      }, 1000);
    }

    if (isLoading || (query?.length as number) < 2) {
      return undefined;
    }

    handler = setTimeout(() => {
      runInAction(() => {
        documentsStore.searchString = query;
      });
    }, 1000);

    return () => {
      if (handler) {
        clearTimeout(handler);
      }
    };
  }, [isLoading, documentsStore, query]);

  const handleChangeFilter = (filterKey: string) => (e: SelectChangeEvent) => {
    const { value } = e.target;
    setFilterStatus((prevStatus) => ({
      ...prevStatus,
      [filterKey]: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleResetFilter = (filterKey: string) => (e: React.MouseEvent) => {
    if (e.target instanceof SVGSVGElement) {
      setFilterStatus((prevStatus) => ({
        ...prevStatus,
        [filterKey]: [],
      }));
      setSelectedFilters((prevStatus) => ({
        ...prevStatus,
        [filterKey]: [],
      }));
    }
    setFilterStatus((prevStatus) => ({
      ...prevStatus,
      [filterKey]: [],
    }));
    setSelectedFilters((prevStatus) => ({
      ...prevStatus,
      [filterKey]: [],
    }));
  };

  const handleConfirmFilter = (filterKey: string, label: string) => () => {
    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      [filterKey]: filterStatus[filterKey],
    }));

    handleClose(label);
  };

  const handleChangeButtonFilter = (selectedName: string) => {
    const selectedItem = buttonItems.find(({ name }) => name === selectedName);
    documentsStore.selectedFilter = selectedItem;
  };

  useEffect(() => {
    runInAction(() => {
      documentsStore.selectedDocumentsType = selectedFilters[filterLabels.DOCUMENT_TYPE].join(',');
    });
  }, [selectedFilters[filterLabels.DOCUMENT_TYPE]]);

  useEffect(() => {
    runInAction(() => {
      documentsStore.selectedCreatedDateGte = dateStartSignal;
    });
  }, [dateStartSignal]);

  useEffect(() => {
    runInAction(() => {
      documentsStore.selectedCreatedDateLte = dateEndSignal;
    });
  }, [dateEndSignal]);

  const selectedName = documentsStore.selectedFilter?.name ?? buttonItems[0].name;

  const getDropDownType = documentsStore.documentsSelectValue?.[+DOCUMENT_TYPE_SWOP[selectedName] - 1];

  return (
    <Box mb="64px">
      {sourceContext === 'contragent' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: OFFSET.m,
          }}
        >
          <ButtonGroupStyled items={buttonItems} outOnClick={handleChangeButtonFilter} selectedName={selectedName} />
          <ButtonStyled text="Добавить документ" startIcon={<AddCircleOutlineIcon />} onClick={toggleOpen} />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: OFFSET.xs,
          marginBottom: OFFSET.s,
          marginTop: `-${OFFSET.xxxxs}`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: OFFSET.xs,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: OFFSET.xs,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>c</Typography>
              <MonitoringDatePicker setDate={setDateStartSignal} width="150px" />
              <Typography sx={{ fontSize: '14px', lineHeight: '20px' }}>по</Typography>
              <MonitoringDatePicker setDate={setDateEndSignal} width="150px" />
            </Box>
            {filterItems.map((filterItem) => (
              <FilterStatus
                key={filterItem.label}
                filterLabel={filterItem.label}
                selectTitle={filterItem.label}
                selectItems={getDropDownType || {}}
                handleOpen={() => handleOpen(filterItem.label)}
                handleClose={() => handleClose(filterItem.label)}
                handleConfirm={handleConfirmFilter(filterItem.label, filterItem.label)}
                handleChangeStatus={handleChangeFilter(filterItem.label)}
                handleResetStatus={handleResetFilter(filterItem.label)}
                statusName={filterStatus[filterItem.label]}
                open={openFilter(filterItem.label)}
                width={filterItem.width}
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: OFFSET.xs,
          }}
        >
          <TextFieldOutlinedStyled
            onChange={onChangeSearch}
            id="search"
            name="search"
            size="small"
            type="text"
            autoComplete="no"
            placeholder="Найти..."
            sx={{ width: WIDTH.s, borderRadius: '0px' }}
            value={query ?? ''}
            endAdornment={
              isLoading && (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              )
            }
            startAdornment={
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            }
          />
          {sourceContext === 'application' && (
            <ButtonStyled text="Добавить документ" startIcon={<AddCircleOutlineIcon />} onClick={toggleOpen} />
          )}
        </Box>
      </Box>
      <Box sx={{ m: 0, mt: OFFSET.m }}>
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              padding: '0px',
              boxShadow: '0',
              bApplicationRadius: 0,
              backgroundColor: 'transparent',
            }}
          >
            <ContractDocumentsTable />
          </Paper>
        </Grid>
      </Box>

      <ContractDocumentsModalCreate openAdd={openAdd} toggleOpen={toggleOpen} />
    </Box>
  );
});
