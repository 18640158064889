import { observable, makeObservable, action, reaction, runInAction } from 'mobx';
import { apiPath } from '@/constants/api';
import { IButtonGroupItem } from '@/constants/types';
import { createAxiosRequest } from '@/utils/request';
import { TableStoreType, TableStore } from '@/stores/TableStore';
import { IRootStore } from '@/stores/type';
import { formattedDateToday } from '@/utils/index';

export type TableMonitoring = {
  id: number;
  account?: Account;
  status_signal: string;
  updated_by: string;
  date_identified: string;
  number_signal: string;
  name_signal: string;
  date_status: string;
  segment: string;
  industry: string;
  company_group: string;
  score: string;
  comment: string;
};

export type GetMonitoringRelation = {
  count: number;
  next?: string;
  previous?: string;
  results: TableMonitoring[];
};

export type Account = {
  id: number;
  inn: string;
  name: string;
};

export interface MonitoringStoreStore {
  rootStore: IRootStore;
  getMonitoring: () => void;
  getMonitoringById: (id: string | number) => Promise<void>;
  getMonitoringSignalById: (id: string | number) => Promise<void>;
  downloadMonitoringAll: () => Promise<void>;
  downloadMonitoringById: (id: string | number, companyName: string) => Promise<void>;
  detailMonitoringById?: TableMonitoring;
  selectedStatusSignal?: string;
  selectedNumberSignal?: string;
  selectedDateIdentifiedGte?: string;
  selectedDateIdentifiedLte?: string;
  selectedOrdering?: string;
  isLoading?: boolean;
  results?: TableMonitoring;

  createMonitoring: (dataToSave: {
    account: string;
    status_signal: string;
    updated_by: number;
    number_signal: string;
    name_signal: string;
    date_status: string;
    date_identified: string;
  }) => void;
  updateMonitoringById: ({
    monitoringId,
    status_signal,
    date_status,
    updated_by,
  }: {
    monitoringId: number;
    status_signal: string;
    date_status: string;
    updated_by: number;
  }) => Promise<void>;
  updateReportMonitoringById: ({
    monitoringId,
    date_status,
    updated_by,
    comment,
    score,
  }: {
    monitoringId: number;
    date_status: string;
    updated_by: number;
    score?: string;
    comment?: string;
  }) => Promise<void>;
  tableBody?: TableMonitoring[];
}

export type MonitoringStoreWithTableStore<T> = MonitoringStoreStore & TableStoreType<T>;

export class MonitoringStore
  extends TableStore<TableMonitoring>
  implements MonitoringStoreWithTableStore<TableMonitoring>
{
  @observable
  public rootStore: IRootStore;

  @observable
  public isLoading: boolean = false;

  @observable
  public results?: TableMonitoring;

  @observable
  public detailMonitoringById?: TableMonitoring;

  @observable
  public selectedStatusSignal?: string;

  @observable
  public selectedNumberSignal?: string;

  @observable
  public selectedDateIdentifiedGte?: string;

  @observable
  public selectedDateIdentifiedLte?: string;

  @observable
  public selectedOrdering?: string;

  @observable
  public isError: boolean = false;

  @observable
  public selectedFilter?: IButtonGroupItem<string>;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.selectedStatusSignal,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.getMonitoring();
        }
      },
    );

    reaction(
      () => this.selectedNumberSignal,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.getMonitoring();
        }
      },
    );

    reaction(
      () => this.selectedDateIdentifiedGte,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getMonitoring();
        }
      },
    );

    reaction(
      () => this.selectedDateIdentifiedLte,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getMonitoring();
        }
      },
    );

    reaction(
      () => this.selectedOrdering,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.getMonitoring();
        }
      },
    );

    reaction(
      () => this.rowsPerPageChange,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getMonitoring();
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value) => {
        if (this.maxSelectedPage <= value) {
          this.getMonitoring();
        }
      },
    );

    reaction(
      () => this.selectedFilter?.type,
      (value, previousValue) => {
        this.handleChangePage(null, 0);
        if (previousValue !== value) {
          this.getMonitoring();
        }
      },
    );

    reaction(
      () => this.searchString,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.getMonitoring();
        }
      },
    );
  }

  @action
  public getMonitoring = async (immediately?: boolean) => {
    const isSearch = this.searchString || this.searchString?.trim();

    this.rootStore.requestTemplate({
      immediately,
      errorMessage: 'Ошибка получения данных мониторинга',
      callback: async () => {
        const limit = isSearch ? 10 : this.rowsPerPageChange;
        const offset = isSearch ? 0 : this.rowsPerPageChange * this.selectedPage;
        const status_signal = this.selectedStatusSignal;
        const number_signal = this.selectedNumberSignal;
        const date_identified__gte = this.selectedDateIdentifiedGte;
        const date_identified__lte = this.selectedDateIdentifiedLte;
        const ordering = this.selectedOrdering ?? '-date_identified';
        const searchString = isSearch ? this.searchString?.trim() : undefined;

        const { data } = await createAxiosRequest<
          {
            offset: number;
            limit: number;
            search?: string;
            status_signal__in?: string;
            number_signal__in?: string;
            date_identified__gte?: string;
            date_identified__lte?: string;
            ordering?: string;
          },
          GetMonitoringRelation
        >({
          path: apiPath.monitoringAccount,
          method: 'GET',
          params: {
            offset,
            limit,
            search: searchString,
            status_signal__in: status_signal,
            number_signal__in: number_signal,
            date_identified__gte: date_identified__gte,
            date_identified__lte: date_identified__lte,
            ordering: ordering,
          },
          useToken: true,
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          const tableData = mapApplicationToTableBody(data.results);
          this.tableBody = [...tableData];
        });
      },
    });
  };

  @action
  getMonitoringById = async (id?: string | number) => {
    const { setIsLoading, setRequestError, isLoading } = this.rootStore;

    try {
      if (!isLoading) {
        const { data } = await createAxiosRequest<{}, TableMonitoring>({
          path: apiPath.monitoringAccountById.replace('{id}', `${id}`),
          method: 'GET',
          useToken: true,
        });
        runInAction(() => {
          this.results = data;
        });
      }
    } catch (e) {
      setRequestError('Ошибка получения данных: Мониторинга');
    } finally {
      setIsLoading(false);
    }
  };

  @action
  getMonitoringSignalById = async (id?: string | number) => {
    this.rootStore.requestTemplate({
      successMessage: 'Мониторинг выполнен',
      errorMessage: `Ошибка выполнения мониторинга`,
      finallyAction: () => this.getMonitoring(),
      callback: async () => {
        if (!this.isLoading) {
          try {
            this.isLoading = true;
            await createAxiosRequest<{}, any>({
              path: apiPath.monitoringSignalAccountById.replace('{id}', `${id}`),
              method: 'POST',
              useToken: true,
            });
          } catch (e) {
            throw e;
          } finally {
            runInAction(() => {
              this.isLoading = false;
            });
          }
        }
      },
    });
  };

  @action
  downloadMonitoringAll = async () => {
    const { setIsLoading, setRequestError, isLoading } = this.rootStore;

    try {
      if (!isLoading) {
        setIsLoading(false);
        const response = await createAxiosRequest<{}, any>({
          path: apiPath.downloadMonitoringAll,
          method: 'GET',
          useToken: true,
          responseType: true,
        });
        const contentType = response.headers['content-type'];
        const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `Мониторинг контрагентов ${formattedDateToday}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (e) {
      setRequestError('Ошибка выгрузки мониторинга');
    } finally {
      setIsLoading(false);
    }
  };

  @action
  downloadMonitoringById = async (id: string | number, companyName: string) => {
    const { setIsLoading, setRequestError, isLoading } = this.rootStore;

    try {
      if (!isLoading) {
        setIsLoading(false);
        const response = await createAxiosRequest<{}, any>({
          path: apiPath.downloadMonitoringById.replace('{id}', `${id}`),
          method: 'POST',
          useToken: true,
          responseType: true,
        });
        const contentType = response.headers['content-type'];
        const url = window.URL.createObjectURL(new Blob([response.data], { type: contentType }));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', `Отчет о результатах мониторинга ${companyName} ${formattedDateToday}.docx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (e) {
      setRequestError('Ошибка выгрузки мониторинга');
    } finally {
      setIsLoading(false);
    }
  };

  @action
  createMonitoring = async (dataToSave: {
    account: string;
    status_signal: string;
    updated_by: number;
    number_signal: string;
    name_signal: string;
    date_status: string;
    date_identified: string;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: 'Данные введены неверно',
      callback: async () => {
        const requestData = {
          account: dataToSave.account,
          status_signal: dataToSave.status_signal,
          updated_by: dataToSave.updated_by,
          number_signal: dataToSave.number_signal,
          name_signal: dataToSave.name_signal,
          date_status: dataToSave.date_status,
          date_identified: dataToSave.date_identified,
        };

        await createAxiosRequest<{}, any>({
          path: apiPath.monitoringAccount,
          method: 'POST',
          data: requestData,
          useToken: true,
        });
      },
      finallyAction: async () => {
        await this.getMonitoring();
      },
    });
  };

  @action
  updateReportMonitoringById = async ({
    monitoringId,
    updated_by,
    comment,
    score,
    date_status,
  }: {
    monitoringId: number;
    updated_by: number;
    score?: string;
    comment?: string;
    date_status: string;
  }) => {
    const { setRequestSuccess, setIsLoading, setRequestError, isLoading } = this.rootStore;

    try {
      if (!isLoading) {
        setIsLoading(false);
        await createAxiosRequest<any, GetMonitoringRelation>({
          path: apiPath.monitoringAccountById.replace('{id}', `${monitoringId}`),
          method: 'PATCH',
          data: {
            date_status,
            updated_by,
            comment,
            score,
          },
          useToken: true,
        });
        setRequestSuccess('Данные успешно сохранены');
      }
    } catch (e) {
      setRequestError('Ошибка обновления мониторинга');
    } finally {
      await this.getMonitoring();
      setIsLoading(false);
    }
  };

  @action
  updateMonitoringById = async ({
    monitoringId,
    status_signal,
    date_status,
    updated_by,
  }: {
    monitoringId: number;
    status_signal: string;
    date_status: string;
    updated_by: number;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка обновления статуса`,
      finallyAction: async () => {
        await this.getMonitoring();
      },
      callback: async () => {
        await createAxiosRequest<any, GetMonitoringRelation>({
          path: apiPath.monitoringAccountById.replace('{id}', `${monitoringId}`),
          method: 'PATCH',
          data: {
            status_signal,
            date_status,
            updated_by,
          },
          useToken: true,
        });
      },
    });
  };
}

const mapApplicationToTableBody = (rawList: TableMonitoring[]) => {
  return rawList?.map((item) => ({
    id: item.id,
    account: item.account,
    status_signal: item.status_signal,
    updated_by: item.updated_by,
    date_identified: item.date_identified,
    number_signal: item.number_signal,
    name_signal: item.name_signal,
    date_status: item.date_status,
    segment: item.segment,
    industry: item.industry,
    company_group: item.company_group,
    score: item.score,
    comment: item.comment,
  })) as TableMonitoring[];
};
