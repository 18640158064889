import { observable, makeObservable, action, reaction, runInAction } from 'mobx';
import { apiPath } from '@/constants/api';
import { APP_ACC_ROLE, APP_STAGE, APP_STATUS_TO_STATUS } from '@/constants/backendEnum';
import { IButtonGroupItem } from '@/constants/types';
import { createAxiosRequest } from '@/utils/request';
import { TableStoreType, TableStore } from '@/stores/TableStore';
import { IRootStore } from '@/stores/type';
import { IGetProducts } from '@/stores/ProductsStore';

export type IApplicationRelation = {
  application: IApplication;
  cntrprt: number;
  collab_days: null | number;
  collab_months: null | number;
  created: string;
  created_by: null | number;
  deferment_days: null | number;
  factoring_type: string;
  id: number;
  legal_entity_type: string;
  limit: null | number;
  role: string;
  updated: string;
  updated_by: null | number;
  responsible_user: string;
};

export type IApplication = {
  created?: string;
  employees?: any[];
  id: number;
  num: string;
  primary_contact?: string;
  source: string;
  stage: string;
  status: string;
  updated: string;
  updated_by: number;
  client_name: string | null;
  staff_fio: string;
  staff_role: string;
  product_name: string;
  type_application: string;
  client_manager: string;
  inn: string;
  responsible_user: string;
};

export type IGetApplications = {
  count: number;
  next?: string;
  previous?: string;
  results: IApplication[];
};

export type IGetApplicationRelation = {
  count: number;
  next?: string;
  previous?: string;
  results: IApplicationRelation[];
};

export type TableApplications = {
  id: string | number;
  number: string | number;
  status: keyof typeof APP_STATUS_TO_STATUS;
  stage: keyof typeof APP_STAGE;
  contact: string;
  contragent: string;
  staffFio: string;
  error_msg?: string;
  role?: keyof typeof APP_ACC_ROLE;
  productName?: string;
  typeApplication?: string;
  created: string;
  client_manager: string;
  responsible_user: string;
  inn: string;
};

export interface IApplicationsStoreStore {
  rootStore: IRootStore;
  getApplicationsById: (id: string, immediately: boolean) => void;
  tableBody?: TableApplications[];
  setContragenId: (id?: number | string) => string | number | undefined;
  contragenId?: number | string;
  createAccountDropdownValue?: { label: string; value: string }[] | undefined;
  createApplicationsDropdownValue?: { label: string; value: string }[] | undefined;
  createAccountSelectValue?: { [key: string]: string } | undefined;
  getDropdownProducts: () => void;
  selectedProduct?: string;
  selectedApplicationType?: string;
  selectedApplicationRole?: string;
  selectedApplicationStatus?: string;
  selectedCreatedDateGte?: string;
  selectedCreatedDateLte?: string;
  clear: () => void;
}

export type IApplicationsStoreStoreWithITableStore<T> = IApplicationsStoreStore & TableStoreType<T>;

export class ApplicationsStore
  extends TableStore<TableApplications>
  implements IApplicationsStoreStoreWithITableStore<TableApplications>
{
  @observable
  public rootStore: IRootStore;

  @observable
  public isError: boolean = false;

  @observable
  public selectedFilter?: IButtonGroupItem<string>;

  @observable
  public contragenId?: string | number;

  @observable
  public createAccountDropdownValue?: { label: string; value: string }[];

  @observable
  public createApplicationsDropdownValue?: { label: string; value: string }[];

  @observable
  public createAccountSelectValue?: { [key: string]: string };

  @observable
  public selectedProduct?: string;

  @observable
  public selectedApplicationType?: string;

  @observable
  public selectedApplicationRole?: string;

  @observable
  public selectedApplicationStatus?: string;

  @observable
  public selectedCreatedDateGte?: string;

  @observable
  public selectedCreatedDateLte?: string;

  @observable
  public isLoading?: boolean = false;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.contragenId,
      async (value, previousValue) => {
        if (value !== previousValue && this.contragenId) {
          await this.getApplicationsById(this.contragenId);
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value, previousValue) => {
        if (this.maxSelectedPage <= value) {
          this.getApplicationsById(this.contragenId);
        }
      },
    );

    reaction(
      () => this.searchString,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.getApplicationsById(this.contragenId);
        }
      },
    );

    reaction(
      () => this.selectedProduct,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getApplicationsById(this.contragenId);
        }
      },
    );

    reaction(
      () => this.selectedApplicationType,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getApplicationsById(this.contragenId);
        }
      },
    );

    reaction(
      () => this.selectedApplicationRole,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getApplicationsById(this.contragenId);
        }
      },
    );

    reaction(
      () => this.selectedApplicationStatus,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getApplicationsById(this.contragenId);
        }
      },
    );

    reaction(
      () => this.selectedCreatedDateGte,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getApplicationsById(this.contragenId);
        }
      },
    );

    reaction(
      () => this.selectedCreatedDateLte,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getApplicationsById(this.contragenId);
        }
      },
    );
  }

  @action
  setContragenId = (id?: string | number) => (this.contragenId = id);

  @action
  getApplicationsById = async (id?: string | number, immediately?: boolean) => {
    const { setIsLoading, setRequestError, isLoading } = this.rootStore;
    const isSearch = this.searchString || this.searchString?.trim();
    const productNames = this.selectedProduct;
    const applicationType = this.selectedApplicationType;
    const applicationRole = this.selectedApplicationRole;
    const applicationStatus = this.selectedApplicationStatus;
    const createdDateGte = this.selectedCreatedDateGte;
    const createdDateLte = this.selectedCreatedDateLte;

    try {
      if (!isLoading || immediately) {
        setRequestError(undefined);
        setIsLoading(true);
        const searchString = isSearch ? this.searchString?.trim() : undefined;
        const limit = this.rowsPerPageChange;
        const offset = this.rowsPerPageChange * this.selectedPage;
        const application__product_name__in = productNames ? this.selectedProduct : undefined;
        const application__type_application = applicationType ? this.selectedApplicationType : undefined;
        const role = applicationRole ? this.selectedApplicationRole : undefined;
        const application__status = applicationStatus ? this.selectedApplicationStatus : undefined;
        const created__gte = createdDateGte ? this.selectedCreatedDateGte : undefined;
        const created__lte = createdDateLte ? this.selectedCreatedDateLte : undefined;
        const { data } = await createAxiosRequest<
          {
            offset: number;
            limit: number;
            search?: string;
            application__product_name__in?: string;
            application__type_application?: string;
            role?: string;
            application__status?: string;
            created__gte?: string;
            created__lte?: string;
          },
          IGetApplicationRelation
        >({
          path: apiPath.applicationsRelationsById.replace('{account_pk}', `${id}`),
          method: 'GET',
          params: {
            offset: this.rowsPerPageChange * this.selectedPage,
            limit: this.rowsPerPageChange,
            search: searchString,
            application__product_name__in,
            application__type_application,
            role,
            application__status,
            created__gte,
            created__lte,
          },
          useToken: true,
        });
        runInAction(() => {
          this.createApplicationsDropdownValue = data?.results?.map((item) => ({
            label: item.application.num,
            value: item.application.id.toString(),
          }));

          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          const tableData = mapApplicationToTableBody(data.results);
          // this.tableBody = [...(this.tableBody ?? []), ...tableData];
          this.tableBody = [...tableData];
        });
      }
    } catch (e) {
      setRequestError('Ошибка получения данных заявки');
    } finally {
      setIsLoading(false);
    }
  };

  @action
  getDropdownProducts = async () => {
    this.rootStore.requestTemplate({
      immediately: true,
      errorMessage: 'Ошибка получения данных продуктов',
      callback: async () => {
        if (!this.isLoading) {
          try {
            this.isLoading = true;
            const { data } = await createAxiosRequest<
              { offset: number; limit: number; status?: string; search?: string },
              IGetProducts
            >({
              path: apiPath.products,
              method: 'GET',
              params: { offset: 0, limit: 100, status: 'ACTIVE' },
              useToken: true,
            });
            runInAction(() => {
              this.createAccountDropdownValue = data?.results?.map((item) => ({ label: item.name, value: item.name }));

              if (data.results) {
                const productNames: { [key: string]: string } = {};
                data?.results?.forEach((item) => {
                  productNames[item.name] = item.name;
                });

                this.createAccountSelectValue = productNames;
              }
            });
          } catch {
            // noop
          } finally {
            this.isLoading = false;
          }
        }
      },
    });
  };

  @action
  clear = () => {
    this.contragenId = undefined;
    this.tableBody = [];
  };
}

const mapApplicationToTableBody = (rawList: IApplicationRelation[]): TableApplications[] => {
  return rawList?.map((item) => ({
    id: item.application.id,
    number: item.application.num,
    status: item.application.status,
    stage: item.application.stage,
    contact: item.application.primary_contact ?? '',
    contragent: item.application?.client_name ?? 'None',
    staffFio: item.application.staff_fio ?? '',
    role: item?.role ?? '',
    created: item?.created ?? '',
    productName: item.application.product_name ?? '-',
    typeApplication: item.application.type_application ?? '-',
    client_manager: item.application.client_manager ?? '-',
    inn: item.application.inn ?? '-',
    responsible_user: item.application.responsible_user ?? '-',
  })) as TableApplications[];
};
