import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';

import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { Box, Divider, ListItemButton, ListItemText, SvgIcon } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon/ListItemIcon';

import { CustomizedSnackbars } from '@/components/common/SnakBar';
import { FullLogo, PartialLogo, Settings, Home, Contragents, Orders, Employees, Eye, Star } from '@/assets';
import { drawerWidth } from '@/constants/magicalNumber';
import { AppBar } from '@/components/common/AppBar/AppBar';
import { ROLE, ROUTES } from '@/constants';
import { useRootStore } from '@/stores';
import { OFFSET } from '@/constants/sizes';
import { color } from '@/constants/colors';

export const iconList = [
  {
    primary: 'Главная',
    IconComponent: Home,
    linkTo: ROUTES.home,
    permission: [ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    primary: 'Контрагенты',
    IconComponent: Contragents,
    linkTo: ROUTES.contragents,
    permission: [ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    primary: 'Заявки',
    IconComponent: Orders,
    linkTo: ROUTES.applications,
    permission: [ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    primary: 'Мониторинг',
    IconComponent: Eye,
    linkTo: ROUTES.monitoring,
    permission: [ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    primary: 'Продукты',
    IconComponent: Star,
    linkTo: ROUTES.products,
    permission: [ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
];

export const iconListBottom = [
  {
    primary: 'Параметры',
    IconComponent: Settings,
    linkTo: ROUTES.settings,
    permission: [ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    primary: 'Группы',
    IconComponent: Employees,
    linkTo: ROUTES.employees,
    permission: [ROLE.GENERAL, ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
];

type ListItemButtonProps = {
  selected: boolean;
};

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(1),
  width: '100%',
  marginLeft: 'auto',
  boxSizing: 'border-box',
  marginRight: 'auto',
  display: 'block',
  minWidth: '100%',
  paddingLeft: '32px',
  paddingRight: '32px',
  backgroundColor: color.white,
}));

const ListItemButtonStyled = styled(
  ListItemButton,
  {},
)<ListItemButtonProps>(() => {
  return {
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      color: color.light_green,
    },
  };
});

export const MainListItems = ({
  open,
  selectedPage,
  iconList,
  userRole,
}: {
  open: boolean;
  selectedPage: string;
  iconList: typeof iconListBottom;
  userRole?: string | null;
}) => {
  const navigate = useNavigate();

  return (
    <>
      {iconList.map(({ primary, IconComponent, linkTo, permission }) => {
        if (!permission.includes(userRole ?? '') && userRole !== ROLE.ADMIN) {
          return null;
        }

        const changePageTo = () => navigate(linkTo, { replace: true });

        return (
          <ListItemButtonStyled
            key={primary}
            onClick={changePageTo}
            selected={primary === selectedPage}
            sx={{ padding: 0, height: '60px' }}
          >
            <SvgIcon
              viewBox="0 0 20 20"
              sx={{
                marginRight: '12px',
                width: 'unset',
                paddingLeft: '12px',
              }}
            >
              <IconComponent stroke={primary === selectedPage ? color.light_green : color.white} />
            </SvgIcon>
            {open && <ListItemText primary={primary} />}
            <ListItemIcon
              sx={{
                marginLeft: '8px',
                ...(open && { marginLeft: '0', minWidth: '0' }),
              }}
            />
          </ListItemButtonStyled>
        );
      })}
    </>
  );
};

const DrawerCustom = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: color.light_black,
    color: color.white,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    overflowX: 'hidden',
    transition: theme.transitions.create(['width', 'span'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create(['width', 'span'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: '49px',
      [theme.breakpoints.up('sm')]: {
        width: '49px',
      },
    }),
  },
}));

export const Drawer = observer((props: any) => {
  const location = useLocation();
  const {
    isLoading,
    userStore,
    employeeAsUserStore,
    positionStore,
    businessUnitStore,
    employeesStore,
    applicationsStore,
    documentsStore,
    uiStateStore: { open, toggleDrawer, toggleContragent },
  } = useRootStore();

  useEffect(() => {
    if (!userStore?.profileInfo) {
      userStore.getProfileInfo();
    }
    if (!userStore?.dictionary) {
      userStore.geListOfVal();
    }

    if (!employeesStore.oneTimeAllUsersLoaded) {
      employeeAsUserStore.getAllEmployeeAsUsers();
      positionStore.getAllPositions();
      businessUnitStore.getAllBusinessUnits();
      employeesStore.getAllEmployee();
    }
  }, [userStore, isLoading, employeeAsUserStore, positionStore, businessUnitStore, employeesStore]);

  useEffect(() => {
    if (!documentsStore.documentsSelectValue) {
      documentsStore.getDocumentsType();
    }
  }, []);

  useEffect(() => {
    if (!applicationsStore.createAccountSelectValue) {
      applicationsStore.getDropdownProducts();
    }
  }, []);

  /// Костыль
  const pageName =
    [...iconList, ...iconListBottom].find(({ linkTo }) => linkTo.match(location.pathname.split('/')[1]))?.primary ?? '';

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar open={open} toggleDrawer={toggleDrawer} toggleScreen={toggleContragent} />
      <DrawerCustom variant="permanent" open={open}>
        <Toolbar
          sx={{
            padding: '0 12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {open ? <FullLogo /> : <PartialLogo />}
        </Toolbar>
        <Box sx={{ display: 'flex', flex: 1 }}>
          <List component="nav" sx={{ padding: 0, width: '100%' }}>
            <MainListItems
              open={open}
              selectedPage={pageName ?? ''}
              iconList={iconList}
              userRole={userStore.profileInfo?.view_set}
            />
          </List>
        </Box>
        <Box mb="30px">
          <Box sx={{ padding: '0 12px' }}>
            <Divider sx={{ backgroundColor: color.light_green }} />
          </Box>
          <List component="nav" sx={{ padding: 0 }}>
            <MainListItems
              open={open}
              selectedPage={pageName ?? ''}
              iconList={iconListBottom}
              userRole={userStore.profileInfo?.view_set}
            />
          </List>
        </Box>
      </DrawerCustom>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          backgroundColor: color.white,
        }}
      >
        <Toolbar />
        <Root
          sx={{
            padding: 0,
            minWidth: '100%',
            paddingLeft: OFFSET.m,
            paddingRight: OFFSET.m,
          }}
        >
          {props.children}
          <CustomizedSnackbars />
        </Root>
      </Box>
    </Box>
  );
});
