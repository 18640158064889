export const color = {
  primary: 'primary',
  transparent: 'transparent',
  black: '#000000',
  graphite_black: '1c1c1c',
  white: '#ffffff',
  white_light: '#e0e0e0',
  light: '#f1f9f7',
  light_gray: '#bdbdbd',
  light_gray_v2: '#ebebeb',
  slate_gray: '#6f8090',
  light_black: '#02161c',
  green: '#5bb798',
  green_v1: '#27bd8a',
  light_green: '#1dc255',
  pale_green: '#def3db',
  gray: '#abb5be',
  grayMain: '#808080',
  gray_v2: '#e9e9e9',
  silver: '#c1cad9',
  aquamarine: '#6fcf97',
  gainsborough: '#e3e3e3',
  wet_asphalt: '#4f4f4f',
  mountain_meadow: '#29bb98',
  sea_green: '#88d8aa',
  periwinkle_crayola: '#faf9fb',
  periwinkle: '#dde4eb',
  forest_wolf: '#eff2ed',
  royal_blue: '#2f80ed',
  light_telegraph: '#cccccc',
  persian_green: '#07ac8b',
  honeydew: '#e3ffed',
  moderately_green: '#c6e1ca',
  pale_pink: '#fdefef',
  carmine_pink: '#e55263',
  light_carmine_pink: '#e97182',
  light_pink_purple: '#ec8fa0',
  green_jungle: '#36ad9e',
  light_pink: '#eb5757',
  dark_orchid: '#a62dd0',
  pale_blue: '#e5e7eb',
  emerald: '#5dc689',
  aquamarine_crayola: '#5ec5dc',
  pear: '#f2c94c',
  dahlia_yellow: '#f69604',
  grayish_blue: '#414b5a',
  american_pink: '#f50057',
  signal_white: '#00000026',
  tropical_trail: '#91d9bf',
  red: '#ff0000	',
  red_pale: '#ff10491a',
  inherit: 'inherit	',
  light_red: '#ff181c	',
  dark_green: '#445371	',
  medium_gray: '#969696',
};
