import React from 'react';
import { sign } from 'jsonwebtoken';
import IframeResizer from 'iframe-resizer-react';

export const MetabaseIframe = React.memo(({ dashboard, params = {} }: { dashboard: number; params?: Object }) => {
  const mbSiteUrl = process.env.REACT_APP_METABASE_SITE_URL;
  const mbSecretKey = process.env.REACT_APP_METABASE_SECRET_KEY ?? '';
  const payload = {
    resource: { dashboard },
    params: { ...params },
    exp: Math.round(Date.now() / 1000) + 10 * 60,
  };
  const token = sign(payload, mbSecretKey);
  const iframeUrl = `${mbSiteUrl}/embed/dashboard/${token}#bordered=false&titled=false`;

  return (
    <IframeResizer
      src={iframeUrl}
      title="Metabase"
      autoResize
      style={{
        border: 'none',
        width: '100%',
      }}
    />
  );
});
