import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { useRootStore } from '@/stores';
import {
  MapAdministrationDataObjectJoinSpec,
  mapAdministrationDataObjectJoinSpec,
} from '@/stores/freskoApi/AdministrationDataObjectJoinStore';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { ModalDelete } from '@/components/common/ModalDelete';
import { TableRowStyled, TableCellSmallHeight } from '@/components/Templates/TableComponentStyled';
import { TableTemplateWithoutStore } from '@/components/Templates/TableTemplateWithoutStore';
import { MoreMenuTabData } from '@/components/Administering/AdministrationTabData/MoreMenuTabData';
import { color } from '@/constants/colors';

const tableHeader = ['Колонка назначения', 'Атрибут источника', 'Константа', ''];

const RenderRowTabConnectionsTable = ({
  filled,
  row,
  handleOpenDeleteModal,
  setEditAttribute,
}: {
  filled: boolean;
  row: MapAdministrationDataObjectJoinSpec[0];
  handleOpenDeleteModal: (row: MapAdministrationDataObjectJoinSpec[0]) => void;
  setEditAttribute: (val: MapAdministrationDataObjectJoinSpec[0] | undefined) => void;
}) => {
  const { destination, attributeName, attributeBdName, common } = row;

  const onDelete = () => {
    handleOpenDeleteModal(row);
  };

  const onEdit = () => {
    setEditAttribute(row);
  };

  return (
    <TableRowStyled key={row.id} sx={{ backgroundColor: filled ? color.light : color.transparent }}>
      <TableCellSmallHeight>
        <LabelLikeHintWithText title={''} value={destination} />
      </TableCellSmallHeight>
      <TableCellSmallHeight>
        <LabelLikeHintWithText reverse title={attributeBdName} value={attributeName} />
      </TableCellSmallHeight>
      <TableCellSmallHeight>
        <LabelLikeHintWithText title={''} value={common} />
      </TableCellSmallHeight>
      <TableCellSmallHeight textAlign="right">
        <MoreMenuTabData onEdit={onEdit} onDelete={onDelete} />
      </TableCellSmallHeight>
    </TableRowStyled>
  );
};

type Props = {
  joinId?: string | number;
  setEditAttribute: (val: MapAdministrationDataObjectJoinSpec[0] | undefined) => void;
};

export const AdministrationDataDetailTabConnectionsTable = observer(({ joinId, setEditAttribute }: Props) => {
  const { administrationDataObjectJoinStore, isLoading } = useRootStore();

  const likeStore = useMemo(
    () => mapAdministrationDataObjectJoinSpec(administrationDataObjectJoinStore.joinSpecValuesByJoinId),
    [administrationDataObjectJoinStore.joinSpecValuesByJoinId],
  );

  const { open, handleClose, handleOpen, deletedValue } = useModalDelete<MapAdministrationDataObjectJoinSpec[0]>();

  const handleDelete = () => {
    if (deletedValue && joinId) {
      administrationDataObjectJoinStore.deleteAdministrationDataObjectJoinSpecById({ id: deletedValue.id, joinId });
    }

    handleClose();
  };

  return (
    <>
      <TableTemplateWithoutStore<any>
        tableHeader={tableHeader}
        RenderComponent={(props) => (
          <RenderRowTabConnectionsTable
            {...props}
            handleOpenDeleteModal={handleOpen}
            setEditAttribute={setEditAttribute}
          />
        )}
        store={{
          renderedRows: (likeStore as any[]) ?? ([] as any[]),
        }}
        isLoading={isLoading}
      />
      <ModalDelete
        uniqId={'Data_table_modal'}
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title="Вы действительно хотите удалить атрибут?"
        descriptions={[deletedValue?.attributeName ?? '', deletedValue?.destination ?? '', deletedValue?.common ?? '']}
      />
    </>
  );
});
