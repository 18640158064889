import { observable, makeObservable, action, reaction, computed, runInAction } from 'mobx';
import { FILTER_TYPE_REPORT } from '@/constants';
import { apiPath } from '@/constants/api';
import type { IButtonGroupItem } from '@/constants/types';
import { createAxiosRequest } from '@/utils/request';
import type { TableStoreType } from '@/stores/TableStore';
import { TableStore } from '@/stores/TableStore';
import type { IContragentReportStore, IRootStore } from '@/stores/type';

export type IReportFile = {
  id: number;
  file_path: string;
  inn: number;
  file_name: string;
  file_type: string;
  list_number: number;
  report_date: string;
  account_id: number;
  created: string;
  updated: string;
  created_by: number;
  inter_report_params: [];
  updated_by: number;
  user_fio: string;
};

export type IInnerReportParams = {
  code: number;
  created: null | string;
  id: number;
  inn: number;
  parameter_date: string;
  report_date: string;
  value: number;

  param_name?: string;
  prev_value?: string;
};

export type IContragentReport = {
  file: IReportFile;
  id: number;
  code: number;
  value: number;
  report_date: string;
  parameter_date: string;
  inn: number;
  created: string;
};

export type IContragentReportByPeriod = IReportFile & {
  inter_report_params: IInnerReportParams[];
};

export type IGetContragentReports<T> = {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
};

export type ITableBodyContragentReport = {
  id: number;
  filePath: string;
  code: number;
  reportDate: string;
  fileName: string;
  fileType: string;
  listNumber: number;
  createdDate: string;
  author: string;
  value: number;
};

export type ITableBodyContragentReportByPeriod = {
  id: number;
  filePath: string;
  reportDate: string;
  fileName: string;
  fileType: string;
  listNumber: number;
  createdDate: string;
  author: string;
  innerReportParams: IInnerReportParams[];
};

export type IContragentReportStoreWithITableStore<T> = IContragentReportStore & TableStoreType<T>;

export class ContragentReportStore
  extends TableStore<ITableBodyContragentReport | ITableBodyContragentReportByPeriod>
  implements IContragentReportStoreWithITableStore<ITableBodyContragentReport | ITableBodyContragentReportByPeriod>
{
  @observable
  public rootStore: IRootStore;

  @observable
  public selectedFilter?: IButtonGroupItem<string>;

  @observable
  public selectedAccountId?: string | number;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.rowsPerPageChange,
      (value, _previousValue) => {
        if (this.maxSelectedPage <= value) {
          this.selectedFilter?.type === FILTER_TYPE_REPORT.BY_PERIOD ? this.getReportsByPeriod() : '';
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value, _previousValue) => {
        if (this.maxSelectedPage <= value) {
          this.selectedFilter?.type === FILTER_TYPE_REPORT.BY_PERIOD ? this.getReportsByPeriod() : '';
        }
      },
    );

    reaction(
      () => this.selectedFilter?.type,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.tableBody = [];
          this.selectedPage = 0;
          value === FILTER_TYPE_REPORT.BY_PERIOD ? this.getReportsByPeriod() : '';
        }
      },
    );

    reaction(
      () => this.selectedAccountId,
      (value, _previousValue) => {
        if (value !== _previousValue) {
          this.selectedFilter?.type === FILTER_TYPE_REPORT.BY_PERIOD ? this.getReportsByPeriod() : '';
        }
      },
    );

    reaction(
      () => this.searchString,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.selectedFilter?.type === FILTER_TYPE_REPORT.BY_PERIOD ? this.getReportsByPeriod() : '';
        }
      },
    );
  }

  @computed
  public get isSearch() {
    return this.searchString || this.searchString?.trim();
  }

  @action
  getReportsByPeriod = async () => {
    this.rootStore.requestTemplate({
      errorMessage: 'Ошибка получения отчета',
      callback: async () => {
        const searchString = this.isSearch ? this.searchString?.trim() : undefined;

        const limit = this.isSearch ? 10 : this.rowsPerPageChange;
        const offset = this.isSearch ? 0 : this.rowsPerPageChange * this.selectedPage;
        const { data } = await createAxiosRequest<
          { offset: number; limit: number; search?: string },
          IGetContragentReports<IContragentReportByPeriod>
        >({
          path: apiPath.reportByPeriod.replace('{account_pk}', `${this.selectedAccountId}`),
          method: 'GET',
          params: { offset, limit, search: searchString },
          useToken: true,
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          const tableData = mapReportByPeriodToTableBody(data.results);
          this.tableBody = [...tableData];
        });
      },
    });
  };

  @action
  uploadReport = async ({ format, contragentId }: { contragentId: string; format: 'word' | 'excel' }) => {
    this.rootStore.requestTemplate({
      errorMessage: 'Ошибка выгрузки отчета',
      callback: async () => {
        const response = await createAxiosRequest<{}, any>({
          path: apiPath.reportUpload
            .replace('{account_pk}', `${contragentId ?? this.selectedAccountId}`)
            .replace('{res_type}', `${format}`),
          method: 'POST',
          useToken: true,
          responseType: true,
        });

        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        const encodedFilename = response.headers['x-filename'];
        const base64String = encodedFilename.replace('=?utf-8?b?', '').trim();
        const decodedFilename = Buffer.from(base64String, 'base64').toString('utf-8');

        if (response.data) {
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(blob);
          downloadLink.download = decodedFilename || 'report';
          downloadLink.click();
        }
      },
    });
  };

  @action
  createReports = async ({ contragentId, file }: { contragentId: string; file: File[] }) => {
    const { setRequestSuccess } = this.rootStore;

    return this.rootStore.requestTemplate({
      successMessage: 'Данные успешно сохранены',
      errorMessage: 'Ошибка создания отчета',
      finallyAction: [
        async () => {
          await this.rootStore.contragentReportBalancePeriodStore.getReportsBalancePeriod();
        },
        async () => {
          await this.rootStore.contragentReportTablePerQuarter.getReportsTable();
          await this.rootStore.contragentReportTablePerQuarter.getReportsTable();
          await this.rootStore.contragentReportTablePerQuarter.getReportsTable();
        },
        async () => {
          await this.rootStore.contragentReportTableLTM.getReportsTable();
        },
      ],
      callback: async (successMessage?: string) => {
        await createAxiosRequest<{}, any>({
          path: apiPath.reportByPeriod.replace('{account_pk}', `${contragentId ?? this.selectedAccountId}`),
          method: 'POST',
          isFile: true,
          data: {
            inter_report_file: file,
          },
          useToken: true,
        });

        setRequestSuccess(successMessage);
      },
    });
  };

  @action
  deleteReports = async ({ contragentId, id }: { contragentId?: string | number; id: string | number }) => {
    this.rootStore.requestTemplate({
      errorMessage: 'Ошибка удаления отчета',
      finallyAction: () => {
        this.selectedFilter?.type === FILTER_TYPE_REPORT.BY_PERIOD ? this.getReportsByPeriod() : '';
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.reportDelete
            .replace('{account_pk}', `${contragentId ?? this.selectedAccountId}`)
            .replace('{id}', `${id}`),
          method: 'DELETE',
          useToken: true,
        });
      },
    });
  };

  @action
  clear = () => {
    this.selectedFilter = undefined;
  };
}

const mapReportToTableBody = (rawList: IContragentReport[]): ITableBodyContragentReport[] => {
  return rawList.map((item) => ({
    id: item.id,
    filePath: item?.file?.file_path,
    code: item.code,
    reportDate: item.report_date,
    fileName: item?.file?.file_name,
    fileType: item?.file?.file_type,
    listNumber: item?.file?.list_number,
    createdDate: item?.file?.created,
    author: item?.file?.user_fio,
    value: item.value,
  })) as ITableBodyContragentReport[];
};

const mapReportByPeriodToTableBody = (rawList: IContragentReportByPeriod[]): ITableBodyContragentReportByPeriod[] => {
  return rawList.map((item, index) => ({
    id: item.id,
    filePath: item?.file_path,
    reportDate: item.report_date,
    fileName: item?.file_name,
    fileType: item?.file_type,
    listNumber: item?.list_number,
    createdDate: item?.created,
    author: item?.user_fio,
    innerReportParams: item?.inter_report_params,
  })) as ITableBodyContragentReportByPeriod[];
};
