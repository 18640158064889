import React from 'react';
import styled from '@emotion/styled';
import { Checkbox, CheckboxProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { color } from '@/constants/colors';

interface IconProps {
  boxSize?: number | string;
  top?: number | string;
  left?: number | string;
  checkIconWidth?: number | string;
  checkIconHeight?: number | string;
}

const BpIcon = styled('span')<IconProps>(({ boxSize }) => ({
  borderRadius: 4,
  width: boxSize || 20,
  height: boxSize || 20,
  border: `0.2px solid ${color.signal_white}`,
  marginLeft: '-5px',
}));

const BpCheckedIcon = styled(BpIcon)<IconProps>(({ top, left, checkIconWidth, checkIconHeight }) => ({
  backgroundColor: color.light_green,
  borderColor: color.light_green,
  '&::before': {
    content: '""',
    display: 'block',
    width: checkIconWidth || 4,
    height: checkIconHeight || 9,
    border: `solid ${color.white}`,
    borderWidth: '0 1.8px 1.8px 0',
    position: 'absolute',
    top: top || '48%',
    left: left || '44%',
    transform: 'translate(-50%, -50%) rotate(45deg)',
    backgroundColor: color.transparent,
  },
}));

export const CheckboxStyled = (props: CheckboxProps & IconProps) => {
  const { boxSize, top, left, checkIconWidth, checkIconHeight, ...rest } = props;

  return (
    <Checkbox
      checkedIcon={
        <BpCheckedIcon
          boxSize={boxSize}
          top={top}
          checkIconWidth={checkIconWidth}
          checkIconHeight={checkIconHeight}
          left={left}
        />
      }
      icon={<BpIcon boxSize={boxSize} />}
      disableRipple
      {...rest}
    />
  );
};
