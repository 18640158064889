import React, { useState } from 'react';

import { Box, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { InputBaseOutlinedStyledStatus } from '@/components/atoms/TextFieldStyled';
import { BadgeStyled } from '@/components/atoms/Bage/BadgeStyled';
import { CheckboxStyled } from '@/components/atoms/Checkbox/CheckboxStyled';
import { OFFSET } from '@/constants/sizes';
import { color } from '@/constants/colors';
import { ButtonStyled } from '@/components/atoms/Button';
import { SelectItemsType } from '@/constants/types';

type FilterProps = {
  filterLabel?: string;
  selectTitle?: string;
  selectItems: SelectItemsType;
  handleConfirm?: () => void;
  handleChangeStatus?: (event: any) => void;
  handleOpen?: () => void;
  handleClose?: () => void;
  handleResetStatus?: (event: React.MouseEvent) => void;
  statusName: string[];
  open?: boolean;
  width: string;
};

type FilterState = Record<string, boolean>;

export const useFilters = () => {
  const [openFilters, setOpenFilters] = useState<FilterState>({});

  const handleOpen = (filterName: string) => {
    setOpenFilters((prevState) => ({ ...prevState, [filterName]: true }));
  };

  const handleClose = (filterName: string) => {
    setOpenFilters((prevState) => ({ ...prevState, [filterName]: false }));
  };

  const open = (filterName: string) => {
    return openFilters[filterName] || false;
  };

  return {
    open,
    handleOpen,
    handleClose,
  };
};

export const FilterStatus = ({
  filterLabel,
  selectTitle,
  selectItems,
  handleConfirm,
  handleChangeStatus,
  handleOpen,
  handleClose,
  handleResetStatus,
  statusName,
  open,
  width,
}: FilterProps) => {
  return (
    <FormControl
      sx={{
        m: 0,
        width: statusName?.length === 0 ? width : `calc(${width} + 40px)`,
      }}
    >
      <InputLabel
        shrink={false}
        sx={{
          color: statusName?.length === 0 ? color.grayMain : color.black,
          fontWeight: '500',
          fontSize: '12px',
          top: '-16%',
          left: statusName?.length === 0 ? '3%' : '-2%',
        }}
        id="demo-multiple-checkbox-label-status"
      >
        {filterLabel}
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label-status"
        id="demo-multiple-checkbox-status"
        multiple
        value={Array.isArray(statusName) ? statusName : []}
        onChange={handleChangeStatus}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        input={<InputBaseOutlinedStyledStatus />}
        renderValue={() => ''}
        IconComponent={() =>
          statusName?.length === 0 ? null : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '18px',
                marginRight: '16px',
              }}
            >
              <BadgeStyled badgeContent={statusName?.length} />
              <CloseIcon style={{ fontSize: 16, cursor: 'pointer' }} onClick={handleResetStatus} />
            </div>
          )
        }
        MenuProps={{
          PaperProps: {
            style: {
              marginTop: '18px',
              padding: '10px 28px 18px 26px',
              borderRadius: '0px',
              boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
              maxHeight: '70%',
              maxWidth: '30%',
            },
          },
        }}
        sx={{
          fontSize: '12px',
          padding: '0',
        }}
      >
        <h3 style={{ margin: '6px 0 12px 5px' }}>{selectTitle}</h3>
        {Object.entries(selectItems).map(([key, value]) => (
          <MenuItem
            key={key}
            value={key}
            sx={{
              padding: '6px 16px 6px 0px',
              height: 'auto',
              gap: '0px',
              '&.Mui-selected': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <CheckboxStyled checked={statusName.indexOf(key) > -1} sx={{ padding: '0 9px' }} />
            <ListItemText
              primary={String(value)}
              primaryTypographyProps={{
                style: { fontSize: '12px', color: color.black, fontWeight: '500', marginLeft: '2px', textWrap: 'wrap' },
              }}
            />
          </MenuItem>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '24px', m: 0, mt: OFFSET.xxs }}>
          <ButtonStyled onClick={handleResetStatus} text="Сбросить" variant="text" height="42px" />
          <ButtonStyled
            disabled={!statusName?.length}
            text="Применить"
            height="44px"
            sx={{ textTransform: 'none' }}
            isFilter={true}
            onClick={handleConfirm}
          />
        </Box>
      </Select>
    </FormControl>
  );
};
