import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Link, styled, Typography } from '@mui/material';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import type { TableStoreType } from '@/stores/TableStore';
import { PaginationCustom } from '@/components/Templates/components/PaginationCustom';
import { TableRowSkeleton } from '@/components/Templates/components/TableRowSkeleton';
import { EmptyTable } from '@/components/Templates/components/EmptyTable';
import { color } from '@/constants/colors';
import { formatToCurrency } from '@/utils';
import { useRootStore } from '@/stores';
import { CheckboxStyled } from '../atoms/Checkbox/CheckboxStyled';
import { OFFSET } from '@/constants/sizes';

export type ITable<T> = {
  totalVisible?: boolean;
  tableHeader: string[];
  tableHeaderWidth?: string[];
  tableTitle?: string | JSX.Element;
  store: TableStoreType<T>;
  isLoading: boolean;
  RenderComponent: ({ row, filled, index }: { row: T; filled: boolean; index: number }) => JSX.Element;
  isHidePagination?: boolean;
  isSkipEmptyImage?: boolean;
  isPageApplicationTable?: boolean;
};

export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  borderBottom: `1px solid ${color.pale_green}`,
}));

export const TableCellStyled = styled(TableCell)(({ theme }) => ({
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '16px',
  padding: '16px 8px',
  borderBottom: `1px solid ${color.pale_green}`,
}));

const typographyStyleBody = {
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '15,3px',
  color: color.black,
};

export const TableTemplate = observer(<T extends { [k: string]: any }>(props: ITable<T>) => {
  const {
    totalVisible,
    tableHeader,
    tableHeaderWidth,
    RenderComponent,
    isLoading,
    store,
    isHidePagination,
    isSkipEmptyImage,
    isPageApplicationTable,
  } = props;

  const rows = useMemo(() => Array.apply(null, Array(store.rowsPerPageChange)), [store.rowsPerPageChange]);

  const sum = store.renderedRows
    .map((el, index) => el.loadedLimitAmount)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  if (isSkipEmptyImage && !store.renderedRows?.length && !isLoading) {
    return null;
  }

  if (!store.renderedRows?.length && !isLoading) {
    return <EmptyTable />;
  }

  return (
    <>
      <Box sx={{ backgroundColor: color.white }}>
        <Table size="medium" sx={{ border: `1px solid ${color.pale_green}` }}>
          <TableHead>
            <TableRowStyled>
              {tableHeader.map((name, index) => {
                return (
                  <TableCellStyled
                    align={
                      (isPageApplicationTable && tableHeader?.length - 2 === index) || tableHeader?.length - 1 === index
                        ? 'right'
                        : undefined
                    }
                    key={index}
                    sx={tableHeaderWidth ? { width: tableHeaderWidth[index] } : undefined}
                  >
                    {name}
                  </TableCellStyled>
                );
              })}
            </TableRowStyled>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRowSkeleton rows={rows} cells={tableHeader} />
            ) : (
              store.renderedRows.map((el, index) => (
                <RenderComponent key={el?.id ?? index} row={el} filled={index % 2 === 0} index={index} />
              ))
            )}
          </TableBody>
          {totalVisible && (
            <TableHead>
              <TableRowStyled>
                <TableCellStyled>Итого</TableCellStyled>
                <TableCellStyled></TableCellStyled>
                <TableCellStyled></TableCellStyled>
                <TableCellStyled>{formatToCurrency(sum)}</TableCellStyled>
              </TableRowStyled>
            </TableHead>
          )}
        </Table>
      </Box>
      <Box
        mt="22px"
        mb="20px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {!isHidePagination && (
          <>
            <PaginationCustom
              rowsPerPageChange={store.rowsPerPageChange}
              summary={store.summary}
              searchString={store?.searchString}
              selectedPage={store.selectedPage}
              handleChangePage={store.handleChangePage}
              disabled={isLoading}
            />
          </>
        )}
      </Box>
    </>
  );
});

export const TableTemplateMonitoring = observer(<T extends { [k: string]: any }>(props: ITable<T>) => {
  const {
    tableHeader,
    tableHeaderWidth,
    RenderComponent,
    isLoading,
    store,
    isHidePagination,
    isSkipEmptyImage,
    isPageApplicationTable,
  } = props;
  const { monitoringStore } = useRootStore();
  const rows = useMemo(() => Array.apply(null, Array(store.rowsPerPageChange)), [store.rowsPerPageChange]);

  const [statusSignal, setStatusSignal] = useState<boolean>(false);
  const [dateIdentified, setDateIdentified] = useState<boolean>(false);

  const handleSortByDate = () => {
    setDateIdentified((prevValue) => !prevValue);
    monitoringStore.selectedOrdering = `${dateIdentified ? '-' : ''}date_identified`;
  };

  const handleSortByDateStatus = () => {
    setStatusSignal((prevValue) => !prevValue);
    monitoringStore.selectedOrdering = `${statusSignal ? '' : '-'}date_status`;
  };

  if (isSkipEmptyImage && !store.renderedRows?.length && !isLoading) {
    return null;
  }

  if (!store.renderedRows?.length && !isLoading) {
    return <EmptyTable />;
  }

  return (
    <>
      <Box sx={{ backgroundColor: color.white }}>
        <Table size="medium" sx={{ border: `1px solid ${color.pale_green}` }}>
          <TableHead>
            <TableRowStyled>
              {tableHeader.map((name, index) => {
                return (
                  <TableCellStyled
                    align={
                      (isPageApplicationTable && tableHeader?.length - 2 === index) || tableHeader?.length - 1 === index
                        ? 'right'
                        : undefined
                    }
                    key={index}
                    sx={isPageApplicationTable && tableHeaderWidth ? { width: tableHeaderWidth[index] } : undefined}
                  >
                    {name === 'Дата выявления' ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                        onClick={handleSortByDate}
                      >
                        {name}
                        <UnfoldMoreOutlinedIcon />
                      </Box>
                    ) : (
                      ''
                    )}
                    {name === 'Дата статуса' ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                        onClick={handleSortByDateStatus}
                      >
                        {name}
                        <UnfoldMoreOutlinedIcon />
                      </Box>
                    ) : (
                      ''
                    )}
                    {name !== 'Дата выявления' && name !== 'Дата статуса' && name}
                  </TableCellStyled>
                );
              })}
            </TableRowStyled>
          </TableHead>

          <TableBody>
            {isLoading ? (
              <TableRowSkeleton rows={rows} cells={tableHeader} />
            ) : (
              store.renderedRows.map((el, index) => (
                <RenderComponent key={el?.id ?? index} row={el} filled={index % 2 === 0} index={index} />
              ))
            )}
          </TableBody>
        </Table>
      </Box>
      <Box
        mt="22px"
        mb="20px"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {!isHidePagination && (
          <PaginationCustom
            rowsPerPageChange={store.rowsPerPageChange}
            summary={store.summary}
            searchString={store?.searchString}
            selectedPage={store.selectedPage}
            handleChangePage={store.handleChangePage}
            disabled={isLoading}
          />
        )}
      </Box>
    </>
  );
});

export const TableTemplateDocuments = observer(
  <T extends { [k: string]: any }>(props: ITable<T> & { handleOpenDeleteModal: (value: string[]) => void }) => {
    const {
      tableHeader,
      tableHeaderWidth,
      RenderComponent,
      isLoading,
      store,
      isHidePagination,
      isSkipEmptyImage,
      isPageApplicationTable,
      handleOpenDeleteModal,
    } = props;
    const { documentsStore } = useRootStore();
    const rows = useMemo(() => Array.apply(null, Array(store.rowsPerPageChange)), [store.rowsPerPageChange]);

    const [documentType, setDocumentType] = useState<boolean>(false);
    const [uploadedBy, setUploadedBy] = useState<boolean>(false);
    const [dateUpload, setDateUpload] = useState<boolean>(false);

    const handleSort = (name: string) => {
      switch (name) {
        case 'Вид документа':
          setDocumentType((prevValue) => !prevValue);
          documentsStore.selectedOrdering = `${documentType ? '' : '-'}document_type`;
          break;

        case 'Кем загружено':
          setUploadedBy((prevValue) => !prevValue);
          documentsStore.selectedOrdering = `${uploadedBy ? '' : '-'}uploaded_by`;
          break;

        case 'Дата загрузки':
          setDateUpload((prevValue) => !prevValue);
          documentsStore.selectedOrdering = `${dateUpload ? '' : '-'}date_uploaded`;
          break;

        default:
          break;
      }
    };

    if (isSkipEmptyImage && !store.renderedRows?.length && !isLoading) {
      return null;
    }

    if (!store.renderedRows?.length && !isLoading) {
      return <EmptyTable />;
    }

    const documentsId = documentsStore.selectedRows ? Array.from(documentsStore.selectedRows) : [];

    const handleDeleteSelected = () => {
      handleOpenDeleteModal(documentsId);
    };

    const handleDownloadSelected = async () => {
      if (documentsId.length > 0) {
        await documentsStore.downloadDocuments({ documentsId });
      }
    };

    return (
      <>
        <Box sx={{ backgroundColor: color.white }}>
          <Table size="medium" sx={{ border: `1px solid ${color.pale_green}` }}>
            <TableHead>
              <TableRowStyled>
                {tableHeader.map((name, index) => {
                  return (
                    <TableCellStyled
                      align={
                        (isPageApplicationTable && tableHeader?.length - 2 === index) ||
                        tableHeader?.length - 1 === index
                          ? 'right'
                          : 'center'
                      }
                      key={index}
                      sx={isPageApplicationTable && tableHeaderWidth ? { width: tableHeaderWidth[index] } : undefined}
                    >
                      {name === '' ? name : ''}
                      {name === 'Чекбокс' ? <CheckboxStyled sx={{ paddingTop: '0', paddingBottom: '0' }} /> : ''}
                      {name !== '' && name !== 'Чекбокс' && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleSort(name)}
                        >
                          {name}
                          <UnfoldMoreOutlinedIcon />
                        </Box>
                      )}
                    </TableCellStyled>
                  );
                })}
              </TableRowStyled>
            </TableHead>

            <TableBody>
              {isLoading ? (
                <TableRowSkeleton rows={rows} cells={tableHeader} />
              ) : (
                store.renderedRows.map((el, index) => (
                  <RenderComponent key={el?.id ?? index} row={el} filled={index % 2 === 0} index={index} />
                ))
              )}
            </TableBody>
          </Table>
        </Box>
        <Box
          mt="22px"
          mb="20px"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {!isHidePagination && documentsStore.selectedRows?.size === 0 && (
            <PaginationCustom
              rowsPerPageChange={store.rowsPerPageChange}
              summary={store.summary}
              searchString={store?.searchString}
              selectedPage={store.selectedPage}
              handleChangePage={store.handleChangePage}
              disabled={isLoading}
            />
          )}
          {documentsStore.selectedRows?.size !== 0 && (
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                alignItems: 'center',
                marginLeft: '24px',
              }}
            >
              <Typography
                component="h3"
                variant="h6"
                color={color.graphite_black}
                sx={{ fontWeight: 600, fontSize: '14px' }}
              >
                Выделено {documentsStore.selectedRows?.size}
              </Typography>
              <Link
                onClick={handleDownloadSelected}
                component="button"
                underline="none"
                sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FileDownloadOutlinedIcon style={{ width: OFFSET.s, height: OFFSET.s, color: color.emerald }} />
                </Box>
                <Typography component="h3" variant="h6" color={color.graphite_black} sx={typographyStyleBody}>
                  Скачать выделенные
                </Typography>
              </Link>
              <Link
                onClick={handleDeleteSelected}
                component="button"
                underline="none"
                sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <DeleteOutlinedIcon sx={{ width: OFFSET.s, height: OFFSET.s, color: color.red }} />
                </Box>
                <Typography component="h3" variant="h6" color={color.graphite_black} sx={typographyStyleBody}>
                  Удалить выделенные
                </Typography>
              </Link>
            </Box>
          )}
        </Box>
      </>
    );
  },
);
