import React, { useState } from 'react';

import {
  Popover,
  MenuItem,
  IconButton,
  ListItemButton,
  ListItemIcon,
  Collapse,
  List,
  ListItemText,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

import { APP_STATUS_CUSTOM, APP_STATUS_CUSTOM_POSSIBLE } from '@/constants/backendEnum';
import { Badge } from '@/components/atoms/Bage/Badge';
import { color } from '@/constants/colors';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

export const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: -7,
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));

export function MenuPopover({ children, sx, ...other }: any) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          p: 1,
          width: 200,
          overflow: 'inherit',
          ...sx,
        },
      }}
      {...other}
    >
      <ArrowStyle className="arrow" />

      {children}
    </Popover>
  );
}

export function MoreMenuButton({ actions, open, onOpen, onClose, download, padding }: any) {
  return (
    <>
      <IconButton size="large" color="inherit" sx={{ opacity: 0.48, padding: padding || '12px' }} onClick={onOpen}>
        {download ? <FileDownloadOutlinedIcon /> : <MoreVertOutlinedIcon width={20} height={20} />}
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 'auto',
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            fontSize: '1rem',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}

export const MoreMenu = ({ currentStatus, menuChangeStatus, handleOpenDeleteModal }: any) => {
  const [open, setOpen] = useState<React.FormEvent<HTMLFormElement> | null>(null);
  const [openSubMenu, setOpenSubMenu] = useState<any>(null);

  const handleOpenMenu = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setOpenSubMenu(null);
  };

  const handleChangeStatus = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const onClickByStatus = (status: string) => {
    handleCloseMenu();
    setOpenSubMenu(!openSubMenu);
    menuChangeStatus(status);
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    handleOpenDeleteModal();
  };

  const possibleNextStatus = APP_STATUS_CUSTOM_POSSIBLE[currentStatus as keyof typeof APP_STATUS_CUSTOM_POSSIBLE] ?? [];

  return (
    <MoreMenuButton
      open={open}
      onClose={handleCloseMenu}
      onOpen={handleOpenMenu}
      actions={
        <>
          <MenuItem
            onClick={handleChangeStatus}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <ListItemButton onClick={handleChangeStatus} sx={{ margin: 0, padding: 0 }}>
              <ListItemText primary="Изменить статус" />
              <ListItemIcon>
                <ArrowForwardOutlinedIcon
                  sx={{
                    color: color.light_green,
                    width: '10px',
                    height: '10px',
                    marginLeft: '69px',
                  }}
                />
              </ListItemIcon>
            </ListItemButton>
            <Collapse in={openSubMenu} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
              <List component="div" disablePadding>
                {possibleNextStatus?.map((item) => (
                  <ListItemButton
                    key={item}
                    onClick={() => onClickByStatus(item)}
                    sx={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <Badge type={item}>{APP_STATUS_CUSTOM[item]}</Badge>
                  </ListItemButton>
                ))}
              </List>
            </Collapse>
          </MenuItem>
          <MenuItem onClick={handleDelete}>Удалить заявку</MenuItem>
        </>
      }
    />
  );
};
