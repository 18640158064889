import React from 'react';

import { Box, Input, Typography } from '@mui/material';

import { color } from '@/constants/colors';

export const typographyStyleBody = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  color: color.black,
};

export const LabelLikeHintWithText = (incomeProps: any) => {
  const { mb, mt, reverse, row, inputVisible, handleInputChange, icon, titleStyle, ...props } = incomeProps;

  return (
    <Box
      mt={mt}
      mb={mb}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: row ? 'center' : undefined,
        gap: row ? '6px' : undefined,
        flexDirection: row ? 'row' : reverse ? 'column-reverse' : 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '0.875rem',
          lineHeight: '1.5rem',
          color: `rgba(0, 0, 0, 0.87)`,
          ...(titleStyle ?? {}),
        }}
      >
        <Typography
          {...props}
          component="h2"
          variant="h6"
          color={color.black}
          gutterBottom
          sx={{
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: '0.875rem',
            lineHeight: '1.5rem',
            color: `rgba(${color.black}, 0.87)`,
            ...(titleStyle ?? {}),
          }}
        >
          {props.title}
        </Typography>
        {icon}
      </Box>
      {props.children}
      {props.value && (
        <Typography {...props} component="h3" variant="h6" color={color.black} gutterBottom sx={typographyStyleBody}>
          {props.value}
        </Typography>
      )}
      {inputVisible && <Input sx={{ fontSize: '14px' }} value={props.input} onChange={handleInputChange} />}
    </Box>
  );
};
